import AccountService from './accountService'
import ApiService from './guideService.api'
import { Meta } from 'model/meta'
import { UserService } from 'service';
import { UserType } from 'model/user';

class GuideService {
  /**
   * @description 是否开始新手引导周期
   * @memberof GuideService
   */
  public isStartCycle = false
  /**
   * @description 标记新手引导周期已经完毕（用来检测在当前会话期间周期完毕）
   * @memberof GuideService
   */
  public isAllCycleDone = false

  /**
   * @description 账套设置初始化完成
   * @type {boolean}
   * @memberof GuideService
   */
  public settingInitDone: boolean = false
  /**
   * @description 数据中心上传文件完成
   * @type {boolean}
   * @memberof GuideService
   */
  public sourceFileUploadDone: boolean = false

  public sourceDone: boolean = false

  public initAccount: any

  /**
 * @description 个人版新手引导开始
 * @returns {Promise<void>}
 * @memberof PersonalCenterService
 */
  public async guideBegin(): Promise<void> {
    this.initAccount = await ApiService.guideBegin()
    return this.initAccount
  }

  /**
   * @description 个人版新手引导完成
   * @returns {Promise<void>}
   * @memberof PersonalCenterService
   */
  public async guideEnd(): Promise<void> {
    return await ApiService.guideEnd()
  }

  /**
   * @description 该用户是否已经完成新手引导并且当前页面的状态为开始新手引导
   * @readonly
   * @type {boolean}
   * @memberof GuideService
   */
  public get isStart(): boolean {
    return UserService.current.type === UserType.personal && this.isServerBegin && this.isStartCycle
  }

  /**
   * @description 检测当前用户是否已完成新手引导（服务器端验证）
   * @readonly
   * @memberof GuideService
   */
  public get isServerBegin() {
    return UserService.current.guide === 'begin'
  }

  /**
   * @description 动画完成时的动作
   * @param {string} str
   * @returns 
   * @memberof GuideService
   */
  public transitionend(str: string) {
    const filedom = document.querySelector(str)
    return new Promise((fresolve, freject) => {
      filedom.addEventListener('transitionend', (e) => {
        fresolve(true)
      })
    })
  }
}

export default new GuideService()

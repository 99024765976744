import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import './text.scss'

@Component
export default class Text extends Vue {
  @Prop({ default: 'base' })
  type: 'main-head' | 'sub-head' | 'head' | 'base' | 'small' | 'assist'

  @Prop({ default: 8 })
  padding: number

  @Prop({ default: false })
  sub: boolean

  @Prop({ default: false })
  bold: boolean

  @Prop({ default: false })
  light: boolean

  @Prop({ default: false })
  center: boolean

  @Prop({ default: false })
  right: boolean

  @Prop({ default: false })
  clip: boolean

  @Prop({ default: false })
  whiteSpace: boolean

  @Prop({ default: false })
  highlight: boolean

  @Prop({ default: false })
  contrast: boolean

  @Prop({ default: false })
  blue: boolean

  @Prop({ default: false })
  done: boolean

  @Prop({ default: false })
  warn: boolean

  @Prop({ default: false })
  error: boolean

  @Prop({ default: false })
  through: boolean

  @Prop({ default: '' })
  title: string

  get calClass() {
    return {
      'jfj-text': true,
      [`jfj-text--${this.type}`]: true,
      'jfj-text--sub': this.sub,
      'jfj-text--center': this.center,
      'jfj-text--right': this.right,
      'jfj-text--bold': this.bold,
      'jfj-text--light': this.light,
      'jfj-text--clip': this.clip,
      'jfj-text--whitespace': this.whiteSpace,
      'jfj-text--highlight': this.highlight,
      'jfj-text--contrast': this.contrast,
      'jfj-text--blue': this.blue,
      'jfj-text--done': this.done,
      'jfj-text--warn': this.warn,
      'jfj-text--error': this.error,
      'jfj-text--through': this.through,
    }
  }

  get calStyle() {
    return {
      'padding': `0 ${this.padding}px`
    }
  }

  render(h) {
    return (
      <p
        class={this.calClass}
        style={this.calStyle}
        title={this.title}>{this.$slots.default}</p>
    )
  }
}
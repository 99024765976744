/**
 * 权限操作表
 *
 * @export
 * @enum {number}
 */
export const enum ActionDefinition {
  /**
   * 表示不受权限控制
   */
  未定义 = 'wooops',

  /**
   * 结算平台模块
   */
  结算平台 = '结算平台',

  /**
   * 数据统计模块
   */
  数据统计 = '数据统计',

  /**
   * 数据统计模块-增长统计
   */
  增长统计 = '增长统计',

  /**
   * 数据统计模块-增长统计-账套图表
   */
  增长统计_账套 = '账套增长',

  /**
   * 数据统计模块-增长统计-客户图表
   */
  增长统计_客户 = '客户增长',

  /**
   * 数据统计模块-增长统计-人员图表
   */
  增长统计_人员 = '人员增长',

  /**
   * 数据统计模块-人员进度统计
   */
  进度统计_人员 = '人员进度统计',

  /**
   * 数据统计模块-客户进度统计
   */
  进度统计_客户 = '客户进度统计',
  /**
   * 数据统计模块-客户进度统计
   */
  企业经营分析 = '企业经营分析',
  /**
   * 人员管理模块
   */
  人员管理 = '人员管理',

  /**
   * 客户管理模块
   */
  客户管理 = '客户管理',

  /**
   * 消息管理模块
   */
  消息管理 = '微信消息',

  /**
   * 账套管理模块
   */
  账套管理 = '账套管理',

  /**
   * 账套管理-分配
   */
  账套管理_账套分配 = '账套分配',

  /**
   * 账套管理-回收
   */
  账套管理_账套回收 = '账套回收',

  /**
   * 人工智能
   */
  人工智能 = '人工智能',

  /**
   * 人工标注
   */
  人工标注 = '人工标注',

  /**
   * 数据监控
   */
  数据监控 = '数据监控',

  /**
   * 回单匹配
   */
  回单匹配 = '回单匹配',

  /**
   * 实时监控
   */
  实时监控 = '实时监控',

  /**
   * 账套列表
   */
  账套列表 = '账套列表',

  /**
   * 账套列表-添加账套
   */
  添加账套 = '添加账套',

  /**
   * 账套列表-删除账套
   */
  删除账套 = '删除账套',

  /**
   * 账套列表-编辑账套
   */
  编辑账套 = '编辑账套',

  /**
   * 数据中心模块
   */
  数据中心 = '数据中心',

  /**
   * 数据中心模块-编辑数据
   */
  编辑元数据 = '编辑数据',

  /**
   * 数据中心模块-数据列表
   */
  数据列表 = '数据列表',

  /**
   * 数据中心模块-计提配置
   */
  计提配置 = '计提配置',

  /**
   * 数据中心模块-业务凭证模板
   */
  业务凭证模板 = '业务凭证模板',

  /**
   * 凭证列表模块
   */
  凭证列表 = '凭证列表',

  /**
   * 凭证列表模块-做账（新建、编辑、审核、计提配置、结账、反结账）
   */
  做账 = '编辑凭证',

  /**
   * 凭证列表模块-凭证批注
   */
  批注凭证 = '批注凭证',

  /**
   * 会计账簿模块
   */
  会计账簿 = '会计账簿',

  /**
   * 财务报表模块
   */
  财务报表 = '财务报表',

  /**
   * 工资模块
   */
  工资模块 = '工资模块',

  /**
   * 工资模块-编辑
   */
  编辑工资 = '编辑工资',

  /**
   * 库存模块
   */
  库存模块 = '库存模块',

  /**
   * 库存模块-编辑
   */
  编辑库存 = '编辑库存',

  /**
   * 固定资产
   */
  固定资产 = '固定资产',

  /**
   * 固定资产模块-编辑
   */
  编辑固定资产 = '编辑固定资产',

  /**
   * 无形资产
   */
  无形资产 = '无形资产',

  /**
   * 无形资产模块-编辑
   */
  编辑无形资产 = '编辑无形资产',

  /**
   * 待摊模块
   */
  摊销模块 = '待摊模块',

  /**
   * 待摊模块-编辑
   */
  编辑摊销 = '编辑待摊',

  /**
   * 辅助核算
   */
  辅助核算 = '辅助核算',

  /**
   * 辅助核算-编辑
   */
  编辑辅助核算 = '编辑辅助核算',

  /**
   * 调账模块-余额监控
   */
  余额监控 = '余额监控',

  /**
   * 调账模块-余额监控-编辑
   */
  编辑余额监控 = '编辑余额监控',

  /**
   * 调账模块-往来调账
   */
  往来调账 = '往来调账',

  /**
   * 调账模块-往来调账-编辑
   */
  编辑往来调账 = '编辑往来调整',

  /**
   * 凭证打印模块
   */
  凭证打印 = '凭证打印',

  /**
   * 凭证打印模块
   */
  编辑凭证打印 = '编辑凭证打印',

  /**
   * 凭证打印模块
   */
  纳税申报 = '纳税申报',

  /**
   * 历史数据模块
   */
  历史数据 = '历史数据',

  /**
   * 历史数据模块-编辑
   */
  编辑历史数据 = '编辑历史数据',

  /**
   * 账套设置模块
   * （未定义）
   */
  账套设置 = '账套设置',

  /**
   * 账套设置模块-科目设置
   */
  科目设置 = '科目设置',

  /**
   * 账套设置模块-科目设置-编辑
   */
  编辑科目 = '编辑科目',

  /**
   * 账套设置模块-初始报表
   */
  初始报表 = '初始报表',

  /**
   * 账套设置模块-初始报表-编辑
   */
  编辑初始报表 = '编辑初始报表',

  /**
   * 账套设置模块-系统设置
   */
  系统设置 = '系统设置',

  /**
   * 账套设置模块-系统设置-编辑
   */
  编辑系统设置 = '编辑系统设置',

  /**
   * 账套设置模块-账套信息
   */
  账套信息 = '账套信息',

  /**
   * 账套设置模块-账套信息-编辑
   */
  编辑账套信息 = '编辑账套信息',

  /**
   * 财务分析模块
   */
  财务分析 = '财务分析',

  /**
   * 内容标注模块
   */
  内容标注 = '内容标注',

  /**
   * 设备管理模块
   */
  设备管理 = '设备管理',

  /**
   * 行业模板模块
   */
  行业模板 = '行业模板',

  /**
   * 模板设置模块
   */
  模板设置 = '模板设置',

  /**
   * 运营管理模块
   */
  运营管理 = '运营管理',

  /**
   * 结算管理模块
   */
  结算管理 = '结算管理',

  /**
   * 资产模块
   */
  资产模块 = '资产模块',

  /**
   * 辅助模块
   */
  辅助模块 = '辅助模块',

  /**
   * 社区聊天
   */
  小君论坛 = '小君论坛',

  /**
   * 个人中心
   */
  个人中心 = '个人中心',

  /**
   * root-数字经营
   */
  数字经营 = '数字经营aa',

  /**
   * root-数字经营-账号管理
   */
  账号管理 = '账号管理',

  /**
   * root-数字经营-报告管理
   */
  报告管理 = '报告管理',

  /**
   * root-数字经营-结算报告
   */
  结算报告 = '结算报告',
  /**
   * 个人中心
   */
  代理商 = '推广管理',

  /**
   * 个人中心
   */
  EPSON代理商 = '推广管理_爱普生',

  /**
   * 个人中心
   */
  Fission代理商 = '推广管理_裂变',
}

/**
 * 权限操作定义
 *
 * @export
 * @interface Action
 */
export interface Action {
  /**
   * 内部名称
   *
   * @type {string}
   * @memberof Action
   */
  name: string;

  /**
   * 别名
   *
   * 用于设置时显示的说明名称
   *
   * @type {string}
   * @memberof Action
   */
  alias: string;

  /**
   * 是否启用
   *
   * @type {boolean}
   * @memberof Action
   */
  enabled: boolean;
}

/**
 * 权限模块定义
 *
 * @export
 * @interface Module
 */
export interface Module {
  /**
   * 内部名称
   *
   * @type {string}
   * @memberof Module
   */
  name: string;

  /**
   * 描述说明
   *
   * @type {string}
   * @memberof Module
   */
  default: string;

  /**
   * 别名
   *
   * 用于设置时显示的说明名称
   *
   * @type {string}
   * @memberof Module
   */
  alias: string;

  /**
   * 是否启用
   *
   * @type {boolean}
   * @memberof Module
   */
  enabled: boolean;

  /**
   * 权限操作定义组
   *
   * @type {Action[]}
   * @memberof Module
   */
  actions: Action[];
}

/**
 * 权限配置组
 *
 * @export
 * @interface Preset
 */
export interface Preset {
  /**
   * id
   *
   * @type {string}
   * @memberof Preset
   */
  _id: string;

  /**
   * 配置名称
   *
   * @type {string}
   * @memberof Preset
   */
  name: string;

  /**
   * 描述说明
   *
   * @type {string}
   * @memberof Preset
   */
  description: string;

  /**
   * 权限模块组
   *
   * @type {Module[]}
   * @memberof Preset
   */
  actions: Module[];
}

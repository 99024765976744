/*
 * @Author: your name
 * @Date: 2020-06-03 10:38:25
 * @LastEditTime: 2020-06-08 11:23:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /jfjun-fsc-front/src/service/jwtService.ts
 */
import CacheService from './cacheService'
import Config from 'config'

class JwtService {
  private jwt: string = ''

  async loadJwt(): Promise<string> {
    if (this.jwt !== '') return this.jwt

    return this.jwt = await CacheService.get('jwt')
  }

  saveJwt(jwt: string) {
    this.jwt = jwt
    CacheService.set('jwt', jwt)
  }

  clearJwt() {
    this.jwt = ''

    CacheService.delete('jwt')
  }
}

export default new JwtService()

import localforage from 'localforage'
const docCookies = {
  getItem: function (sKey) {
    return decodeURIComponent(document.cookie.replace(new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1')) || null
  },
  setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false }
    var sExpires = ''
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires = vEnd === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : '; max-age=' + vEnd
          break
        case String:
          sExpires = '; expires=' + vEnd
          break
        case Date:
          sExpires = '; expires=' + vEnd.toUTCString()
          break
      }
    }
    document.cookie = encodeURIComponent(sKey) + '=' + encodeURIComponent(sValue) + sExpires + (sDomain ? '; domain=' + sDomain : '') + (sPath ? '; path=' + sPath : '') + (bSecure ? '; secure' : '')
    return true
  },
  removeItem: function (sKey, sPath, sDomain) {
    if (!sKey || !this.hasItem(sKey)) { return false }
    document.cookie = encodeURIComponent(sKey) + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' + (sDomain ? '; domain=' + sDomain : '') + (sPath ? '; path=' + sPath : '')
    return true
  },
  hasItem: function (sKey) {
    return (new RegExp('(?:^|;\\s*)' + encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&') + '\\s*\\=')).test(document.cookie)
  },
  keys: /* optional method: you can safely remove it! */ function () {
    var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '').split(/\s*(?:\=[^;]*)?;\s*/)
    for (var nIdx = 0; nIdx < aKeys.length; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]) }
    return aKeys
  }
}
class CacheService {
  private storage: LocalForage

  open(scope: string) {
    this.storage = localforage.createInstance({ name: scope })
  }

  close() {
    this.storage = null
  }

  async get(key: string): Promise<any> {
    const data: any = await this.storage.getItem(key)

    return data == null ? void 0 : data
  }

  set(key: string, data: any): Promise<void> {
    if (this.storage == void 0) {
      console.warn('[Cache] No opened storage.')
    } else {
      return this.storage.setItem(key, data).catch((e) => { })
    }
  }
  setCookie(key: string, value: any, domain: string = '/') {
    docCookies.setItem(key, value, '', '/', domain, null)
  }
  removeCookie(key: string, domain: string = '/') {
    docCookies.removeItem(key, '/', domain)
  }

  delete(key: string): Promise<void> {
    if (this.storage == void 0) {
      console.warn('[Cache] No opened storage.')
    } else {
      return this.storage.removeItem(key)
    }
  }

  clear(): Promise<void> {
    if (this.storage == void 0) {
      console.warn('[Cache] No opened storage.')
    } else {
      return this.storage.clear()
    }
  }
}

export default new CacheService()
import { ApiService as service, ApiServiceBase, ApiCallingFn } from 'service/apiService'

interface ApiService extends ApiServiceBase {
  getSubjectList: ApiCallingFn
  getSubjectListByRule: ApiCallingFn
  createSubject: ApiCallingFn
  deleteSubject: ApiCallingFn
  editSubject: ApiCallingFn
  suspendSubject: ApiCallingFn

  cleanupSubject: ApiCallingFn

  /**
   * @description 合并科目
   * @type {ApiCallingFn}
   * @memberof ApiService
   */
  mergeSubject: ApiCallingFn
}

const ApiService = service as ApiService
ApiService.define([
  { type: 'json', name: 'getSubjectList', path: 'subject/list' },
  { type: 'json', name: 'getSubjectListByRule', path: 'subject/list/economics' },
  { type: 'json', name: 'createSubject', path: 'subject/create' },
  { type: 'json', name: 'deleteSubject', path: 'subject/delete' },
  { type: 'json', name: 'editSubject', path: 'subject/edit' },
  { type: 'json', name: 'mergeSubject', path: 'subject/merge' },
  { type: 'json', name: 'suspendSubject', path: 'subject/suspend' },
  { type: 'json', name: 'cleanupSubject', path: 'subject/cleanup' },
])

export default ApiService
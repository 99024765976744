import { ApiService as service, ApiServiceBase, ApiCallingFn } from 'service/apiService'

interface ApiService extends ApiServiceBase {
  relearnRecord: ApiCallingFn
  confirmRelearnRecords: ApiCallingFn
  relearnFormula: ApiCallingFn
  confirmRelearnFormula: ApiCallingFn
  relearnCategory: ApiCallingFn
}

const ApiService = service as ApiService
ApiService.define([
  { type: 'json', name: 'relearnRecord', path: 'metadata/transaction/relearn' },
  { type: 'json', name: 'relearnFormula', path: 'metadata/listByTransaction' },
  { type: 'json', name: 'confirmRelearnRecords', path: 'metadata/transaction/relearnSubmit' },
  { type: 'json', name: 'confirmRelearnFormula', path: 'metadata/voucher/rebuild' },
  { type: 'json', name: 'relearnCategory', path: 'metadata/transaction/relearnByCategory' },
])

export default ApiService

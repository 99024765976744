import Vue, { PluginObject } from 'vue'
import { Workflow } from '../workflow.util'

interface Spinner {
  /**
   * 
   * 显示加载spinner
   * 
   * @memberof Spinner
   */
  show: (msg: string) => void,

  /**
   * 
   * 隐藏加载spinner
   * 
   * @memberof Spinner
   */
  hide: () => void
}

interface SpinnerOptions { }

declare module "vue/types/vue" {
  interface Vue {
    $spin: Spinner
  }
}

const spinner: Spinner = {
  show(msg: string) {
    Workflow.start('spinner.show', msg)
  },
  hide() {
    Workflow.start('spinner.hide')
  }
}

const installer: PluginObject<SpinnerOptions> = {
  install(vue: typeof Vue, options?: SpinnerOptions): void {
    vue.prototype.$spin = spinner
  }
}

export default installer
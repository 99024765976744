import { Entry } from 'model/entry'
import { trimNum, sum } from 'util/common'

/**
 * 获取分录指定方向的合计数
 *
 * @export
 * @param {Entry[]} entries 分录数组
 * @param {('debit' | 'credit')} direction 借贷方向字段
 * @returns 合计数，保留两位小数
 */
export function getSum(entries: Entry[], direction: 'debit' | 'credit'): number {
  return sum(entries, direction)
}

/**
 * 获取分录借贷方总和的差额
 *
 * @export
 * @param {Entry[]} entries 分录数组
 * @returns 差额，保留两位小数
 */
export function getDiff(entries: Entry[]): number {
  return trimNum(getSum(entries, 'debit') - getSum(entries, 'credit'))
}

/**
 * 交换借贷方金额
 *
 * @export
 * @param {Entry} entry 需要交换的分录
 */
export function swapDirection(entry: Entry) {
  ([entry.debit, entry.credit] = [entry.credit, entry.debit])
}

/**
 * 自动平衡
 *
 * @export
 * @param {Entry} current 当前分录
 * @param {'debit' | 'credit' | ''} direction 需要填值的方向，留空自动推算
 * @param {Entry[]} entries 需要计算的分录组
 */
export function fillDiff(current: Entry, direction: 'debit' | 'credit' | '', entries: Entry[]) {
  const diff = getDiff(entries)
  if (diff === 0) return

  if (direction === '') {
    ([current.debit, current.credit] = diff < 0
      ? [-diff, 0]
      : [0, diff])
  } else {
    const delta = direction === 'debit' ? diff : -diff
    current[direction] = current[direction] - delta
  }
}

/**
 * 将指定数字转成千分位分隔的货币格式
 *
 * @export
 * @param {number} num 需要转换的数字
 * @param {number} [digits=2] 需要保留的小数位数
 * @returns {string} 指定数字的货币格式
 */
export function toCurrency(num: number, digits: number = 2): string {
  const trimnum = trimNum(num, digits)
  const cash = Math.abs(trimnum).toFixed(digits).toString()

  const pos = digits === 0 ? cash.length : cash.indexOf('.')

  const symbol = trimnum < 0 ? '-' : ''
  const integer = cash.slice(0, pos)
  const point = digits === 0 ? '' : '.'
  const fraction = cash.slice(pos + 1, cash.length)

  function format(s: string): string {
    if (s.length <= 3) {
      return s
    } else {
      const sec = s.slice(-3, s.length)
      return format(s.slice(0, -3)).concat(',').concat(sec)
    }
  }

  return symbol + format(integer) + point + fraction
}

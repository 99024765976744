import makeReactive from 'util/makeReactive'
import { Account } from 'model/account'
import ApiService from './accountService.api'
import UserService from './userService'
import CacheService from './cacheService'
import { AccountTaxPeriod, AccountTaxType } from 'model/account'


import Issue from 'model/issue'
import { UserType } from 'model/user';

class AccountService {
  public totalCounts: number = 0
  private _version: number = 0
  private _accounts: Account[] = []
  private account: Account = null

  public get version(): number {
    return this._version
  }

  public get current(): Account | null {
    return this.account
  }

  public get accounts(): Account[] {
    return this._accounts
  }

  /**
   * 当账套类型为季报时判断
   * 当前是否是季报期（3，6，9，12）
   *
   * @readonly
   * @type {boolean}
   * @memberof AccountService
   */
  public get isQuarterlyReport(): boolean {
    if (this.current.taxPeriod === AccountTaxPeriod.季报) {
      const issue = new Issue(this.current.selectedIssue)
      return /3|6|9|12/.test(issue.month.toString())
    }
    return false
  }
  /**
   * @description 当前是否时季度月（不区分月报和季报）
   * @readonly
   * @type {boolean}
   * @memberof AccountService
   */
  public get isQuarterlyAndMonthReport(): boolean {
    const issue = new Issue(this.current.selectedIssue)
    return /3|6|9|12/.test(issue.month.toString())
  }

  /**
   * @description 是否为月报
   * @readonly
   * @type {boolean}
   * @memberof AccountService
   */
  public get isQuarterlyMonthReport(): boolean {
    return this.current.taxPeriod === AccountTaxPeriod.月报
  }

  /**
   * 小规模纳税人季报
   *
   * @readonly
   * @type {boolean}
   * @memberof AccountService
   */
  public get isSmallReport(): boolean {
    return this.current.taxPeriod === AccountTaxPeriod.季报 && this.current.taxpayerType === AccountTaxType.小规模纳税人
  }

  /**
   * 当账套类型是否为为个人独资企业或有限合伙
   *
   * @readonly
   * @type {boolean}
   * @memberof AccountService
   */
  public get isQuarterlyType(): boolean {
    return this.current.taxpayerType === AccountTaxType.个人独资企业或有限合伙
  }

  /**
   * @description 检查当前账套是否为新建的，（新建账套2分钟内不允许上传一些文件）
   * @memberof FileUploaderService
   */
  public checkAccountCreateTime(account: Account) {
    // const time = new Date().getTime() - new Date(account.createdAt).getTime()
    // if (time <= 2 * 60 * 1000) {
    //   throw new Error("账套正在进行系统初始化，请在2分钟之后上传数据。")
    // }
  }

  public get isCurrentIssue(): boolean {
    return this.account.selectedIssue === this.account.currentIssue
  }

  public get isFscAccount() {
    return this.account.clientType === '基础财税'
  }

  public async fetchAccountList(issue: string = Issue.systemIssue.toIssueStr()): Promise<void> {
    const accounts = await ApiService.fetchAccounts({ issue })
    this._accounts = (accounts as any[]).map((account) => new Account(account))
    this.totalCounts = this._accounts.length

    CacheService.set('accounts', this._accounts)
  }
  public async exportAccountList(issue: string = Issue.systemIssue.toIssueStr()) {
    return await ApiService.exportAccounts({
      issue,
      type: 'account'
    })
  }

  /**
   * 欠费提醒
   *
   * @returns
   * @memberof AccountService
   */
  public async oweToRemind() {
    return await ApiService.fetchOweToRemind({
      group: this.current.group,
      ac: this.current.acId
    })
  }

  public async loadCache(): Promise<void> {
    try {
      if (this.account != null) return

      const account = await CacheService.get('account')
      if (account != null) {
        const newAccount = await ApiService.getAccountDetail({
          accountId: account._id
        })
        this.account = new Account(Object.assign(account, newAccount))
      }

      const accounts = await CacheService.get('accounts')
      if (accounts != null) this._accounts = accounts
    } catch (e) {
      console.error(e)
    }
  }

  public saveCache(): Promise<void> {
    return CacheService.set('account', this.account)
  }

  public clearCache(): Promise<void> {
    this.account = null
    return CacheService.delete('account')
  }

  public async create(info: Partial<Account>) {
    const { _id } = await ApiService.createAccount(info)
    await this.fetchAccountList()
    return this._accounts.find((account) => account._id === _id)
  }
  //批量导入账套
  public async import(url: string, ac: string) {
    let response = await ApiService.importAccounts({ url, ac: ac })

    await this.fetchAccountList()

    return response

  }
  //获取批量导入账套模板
  public async getTemplate(type: string) {

    const { url } = await ApiService.getTemplate({
      type
    })
    return url

  }
  public async delete(account: Account) {
    await ApiService.deleteAccount({ accountId: account._id })
    await this.fetchAccountList()
  }

  public async carryover(
    account: Account = this.current,
    issue: string = this.current.selectedIssue,
  ): Promise<{ equilibrium: boolean, report: any[] }> {
    const { currentIssue, issueState, equilibrium, report } = await ApiService.carryover({
      accountId: account._id,
      issue: account.selectedIssue,
    })

    if (this.current) {
      this.current.currentIssue = currentIssue
      this.current.equilibrium = equilibrium

      this.saveCache()
      this._version++
    }

    return { equilibrium, report }
  }

  public async rollback(): Promise<{ equilibrium: boolean, report: any[] }> {
    const { currentIssue, equilibrium, report } = await ApiService.rollback({
      accountId: this.current._id,
      issue: this.current.selectedIssue,
    })

    if (this.current) {
      this.current.currentIssue = currentIssue
      this.current.equilibrium = equilibrium

      this.saveCache()
      this._version++
    }

    return { equilibrium, report }
  }

  public fetchEquilibriumInfo(id?: string, issue?: string): Promise<any[]> {
    return ApiService.getEquilibriumInfo({
      accountId: id || this.current._id,
      issue: issue || this.current.selectedIssue,
    })
  }

  public fetchAuditReports(
    account: Account = this.current,
    issue: string = this.current.selectedIssue,
  ): Promise<any> {
    return ApiService.getAuditReports({
      accountId: account._id,
      issue: issue,
    })
  }

  /**
   * 本年累计、本月累计调换位置
   * @param {boolean} sheetYearAfter 本年累计是否靠后
   * @memberof AccountService
   */
  public async editSheetYearAfter(sheetYearAfter: boolean) {
    await ApiService.editSheetYearAfter({
      _id: this.account._id,
      sheetYearAfter,
    })

    this.account.sheetYearAfter = sheetYearAfter
    this.saveCache()
  }

  /**
   * 更新账套信息
   *
   * @memberof AccountService
   */
  public async updateAccountDetail(account: any) {
    await ApiService.updateAccountDetail({
      accountId: account._id,
      ...account,
    })
  }

  public getAccountState(id?: string, issue?: string): Promise<string> {
    return ApiService.getAccountState({
      accountId: id || this.current._id,
      issue: issue || this.current.selectedIssue,
    })
  }

  public update(props: { [key: string]: any }) {
    Object.assign(this.account, props)
    this.saveCache()
  }

  public switchAccount(account: Account) {
    this.account = account
    this._version++

    this.saveCache()
  }

  public switchIssue(issue: string) {
    this.account.selectedIssue = issue
    this._version++
    this.saveCache()
  }

  public isMyAccount(account: Account = this.current): boolean {
    const user = UserService.current

    return user._id === account.acAccountantId ||
      user._id === account.fscAccountantId
  }

  public clearAccounts() {
    this._accounts = []
  }

  /**
   * 查看账套信息
   */
  public async getAccountDetail(): Promise<any> {
    return await ApiService.getAccountDetail({ accountId: this.current._id })
  }

}

export default makeReactive(new AccountService())

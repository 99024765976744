
import { User, UserType } from 'model/user'
import { prune } from 'util/common'
import makeReactive from 'util/makeReactive'
import ApiService from './userService.api'
import WsService from './wsService'
import AuthService from './authService'
import CacheService from './cacheService'
import Axios, { AxiosError } from 'axios'

class UserService {
  private user: User = null
  public wxInfo: any = {}

  public get current(): User | null {
    // TODO: should return a deep copy
    return this.user
  }

  public set current(value: User) {
    this.user = value
  }

  public get isFscUser() {
    return this.user.client === '基础财税'
  }
  public get isJfjroot() {
    return this.user.type === 'jfjAdmin'
  }
  /**
   * @description 极速版
   * @readonly
   * @memberof UserService
   */
  public get isCsUser() {
    return this.user.type === UserType.personal
  }

  public async login(username: string, password: string): Promise<User> {
    this.user = new User(await WsService.connect(username, password))
    AuthService.fillUserBlacklist(this.user.deny)

    CacheService.open(this.user._id)
    await CacheService.set('type', this.user.type)
    await CacheService.set('client', this.user.client)
    await CacheService.set('routepkg', this.user.routepkg)
    await CacheService.set('source', this.user.source)
    return this.user
  }

  public async loginByVerification(phone: string, password: string, verification: string): Promise<User> {
    this.user = new User(await WsService.connectByVerification(phone, password, verification))
    AuthService.fillUserBlacklist(this.user.deny)

    CacheService.open(this.user._id)
    await CacheService.set('type', this.user.type)
    await CacheService.set('client', this.user.client)
    await CacheService.set('routepkg', this.user.routepkg)
    await CacheService.set('source', this.user.source)
    return this.user
  }

  public async loginByWx(code: string): Promise<any> {
    let ret = await WsService.connectByWx(code)
    if (ret.err) {
      this.wxInfo = ret.wxInfo
      return ret
    } else {
      this.user = new User(ret)
      AuthService.fillUserBlacklist(this.user.deny)
      CacheService.open(this.user._id)
      await CacheService.set('type', this.user.type)
      await CacheService.set('client', this.user.client)
      await CacheService.set('routepkg', this.user.routepkg)
      await CacheService.set('source', this.user.source)
      return ret
    }
  }
  /**通过钉钉登入 */
  public async loginByDD({ corpId, code }): Promise<any> {
    let ret = await WsService.connectByDD({ corpId, code })
    if (ret.err) {
      this.wxInfo = ret.wxInfo
      this.wxInfo.openid = ret.openid
      return ret
    } else {
      this.user = new User(ret)
      AuthService.fillUserBlacklist(this.user.deny)
      CacheService.open(this.user._id)
      await CacheService.set('type', this.user.type)
      await CacheService.set('client', this.user.client)
      await CacheService.set('routepkg', this.user.routepkg)
      await CacheService.set('source', this.user.source)
      return ret
    }
  }

  public async sendAuthcode(phone: string): Promise<any> {
    return await WsService.sendAuthcode({
      phone
    })
  }

  public async registerByWx({ phone = '', authcode = '', password = '', agentParam = '', level = undefined }): Promise<any> {
    let sendMsg =
      password === ''
        ? {
            phone,
            verification: authcode,
            openid: this.wxInfo.openid,
            wxInfo: this.wxInfo,
            agentParam,
            level
          }
        : {
            phone,
            verification: authcode,
            openid: this.wxInfo.openid,
            wxInfo: this.wxInfo,
            password,
            agentParam,
            level
          }

    let ret = await WsService.registerByWx(sendMsg)

    this.user = new User(ret)

    AuthService.fillUserBlacklist(this.user.deny)
    CacheService.open(this.user._id)
    await CacheService.set('type', this.user.type)
    await CacheService.set('client', this.user.client)
    await CacheService.set('routepkg', this.user.routepkg)
    await CacheService.set('source', this.user.source)
    return this.user
  }

  public async registerByDD({ phone = '', authcode = '', password = '' }): Promise<any> {
    let sendMsg = {
      phone,
      verification: authcode,
      openid: this.wxInfo.openid,
      wxInfo: this.wxInfo
    }

    let ret = await WsService.registerByDD(sendMsg)

    this.user = new User(ret)

    AuthService.fillUserBlacklist(this.user.deny)
    CacheService.open(this.user._id)
    await CacheService.set('type', this.user.type)
    await CacheService.set('client', this.user.client)
    await CacheService.set('routepkg', this.user.routepkg)
    await CacheService.set('source', this.user.source)
    return this.user
  }

  public async refresh(session: string): Promise<User> {
    const d = await WsService.refresh(session)

    this.user = new User(d)
    AuthService.fillUserBlacklist(this.user.deny)

    CacheService.open(this.user._id)
    await CacheService.set('type', this.user.type)
    await CacheService.set('client', this.user.client)
    await CacheService.set('routepkg', this.user.routepkg)
    await CacheService.set('source', this.user.source)
    return this.user
  }

  public logout() {
    WsService.disconnect()
    AuthService.clearUserBlacklist()
    AuthService.clearAccountBlacklist()
    this.user = null
  }

  public async loadAssociation(userId: string): Promise<string> {
    CacheService.open(userId)
    return CacheService.get('client')
  }

  public async loadUserType(userId: string): Promise<string> {
    CacheService.open(userId)
    return CacheService.get('type')
  }

  public async loadUserSource(userId: string): Promise<string> {
    CacheService.open(userId)
    return CacheService.get('source')
  }

  public async loadRoutePkg(userId: string): Promise<any> {
    CacheService.open(userId)
    return CacheService.get('routepkg')
  }

  public async updatePassword(info: { newPassword: string; oldPassword: string }): Promise<void> {
    await ApiService.updatePassword(info)
  }

  public async editUserInfo(info: { name: string; email: string; phone: string }) {
    const resData = await ApiService.editUserInfo(info)
    this.user = new User(resData.user)
    return resData.jwt
  }

  public async getRemind_userService() {
    const resData = await ApiService.getRemind_userService()
    return resData
  }
  public async editPersonalPassword({ password = '' }) {
    const resData = await ApiService.editPersonalPassword({ password })
    return resData
  }

  public async editPersonalName({ name = '' }) {
    const resData = await ApiService.editPersonalName({ name })
    this.user = new User(resData.user)
    return resData.jwt
  }

  public async editPersonalBindInfo(info) {
    const resData = await ApiService.editBindInfo_personalCenterEpsonProxy(info)
    this.user = new User(resData.user)
    return resData.jwt
  }
  /**极速版用户注册完成个人引导后获取优惠卷 */
  public async get_ticket() {
    return ApiService.get_ticket()
  }
}

export default makeReactive(new UserService())

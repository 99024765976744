import makeReactive from 'util/makeReactive'
import { findNdelete, findNreplace } from 'util/common'
import AccountService from './accountService'
import ApiService from './voucherService.api'
import { Voucher, VoucherAuditState, FscSummarizingRules } from 'model/voucher'
import { Resource } from 'model/resource'
import { UserService, AuthService } from 'service'
import { ActionDefinition } from 'model/auth';

interface Parities {
  name: string,
  begin: number,
  end: number
}

class VoucherService {
  private innerVouchers: Voucher[] = []
  private innerParities: Parities[] = []
  private _version: number = 0

  private _summarizingRules: any[] = []

  /**
   * @description 清空数据（数据大的情况下会卡死）
   * @memberof VoucherService
   */
  public resetService() {
    this.innerVouchers = []
    this.innerParities = []
    this._summarizingRules = []
    this._version = 0
  }
  public get vouchers(): Voucher[] {
    return this.innerVouchers
  }

  public get parities(): Parities[] {
    return this.innerParities
  }

  public get version(): number {
    return this._version
  }

  public get order(): 'asc' | 'desc' {
    if (this.innerVouchers.length === 0) return 'desc'

    return parseInt(this.innerVouchers[0].number as any, 10) === 1 ? 'asc' : 'desc'
  }

  /**
   * 获取凭证编辑汇总规则
   * @description
   * @readonly
   * @type {any[]}
   * @memberof VoucherService
   */
  public get summarizingRules(): any[] {
    return this._summarizingRules
  }

  public async fetchVouchers() {
    const vouchers = await ApiService.getVouchersList({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
    })

    this.innerVouchers = vouchers.map((raw) => new Voucher(raw))
    this._version++
  }

  /**
   * 获取凭证编辑汇总规则
   * 基础财税为固定，管理帐为后台获取
   *
   * @memberof VoucherService
   */
  public async fetchSummarizingRules() {
    // 管理帐用户
    if (UserService.current.isMgrRelated) {
      const { tables } = await ApiService.fetchIndustryDetail({
        name: UserService.current.client,
        menuName: '数据中心',
        client2: AccountService.current.client2,
        isPrivate: AccountService.current.isPrivate,
        accountId: AccountService.current._id,
        level: AccountService.current.level,
      })
      this._summarizingRules = tables
    } else {
      // 基础财税用户
      this._summarizingRules = FscSummarizingRules
    }
  }

  public async fetchVoucher(voucherId: string, issue?: string): Promise<Voucher> {
    const voucher = this.innerVouchers.find((v) => v._id === voucherId)

    return (voucher !== void 0 && !voucher.resourcesCount)
      ? voucher
      : new Voucher(await ApiService.getVoucherDetail({
        accountId: AccountService.current._id,
        issue: issue !== void 0 ? issue : AccountService.current.selectedIssue,
        voucherId,
      }))
  }

  public async createVoucher(voucher: Voucher) {
    const newVoucher: Voucher = new Voucher(await ApiService.createVoucher({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      entries: voucher.entries,
      attachesCount: voucher.attachesCount,
      createTime: voucher.createTime,
      number: voucher.number,
      lockNumber: voucher.lockNumber,
    }))

    this.vouchers.push(newVoucher)
    this._version++
  }

  public async deleteVoucher(voucher: Voucher) {
    const vouchers = await ApiService.deleteVoucher({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      voucherId: voucher._id,
    })

    this.innerVouchers = vouchers.map((raw) => new Voucher(raw))
    this._version++
  }

  public async updateVoucher(voucher: Voucher) {
    const newVoucher: Voucher = new Voucher(await ApiService.updateVoucher({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      voucher,
    }))

    findNreplace(this.vouchers, newVoucher, (v) => v._id === voucher._id)
    this._version++
  }

  public updateVoucherNumber(voucher: Voucher, num: number): Promise<void> {
    return ApiService.updateVoucherNumber({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      voucher,
      number: num,
    })
  }
  /**
   * @description 删除所有凭证
   * @returns {Promise<void>}
   * @memberof VoucherService
   */
  public removeAll(): Promise<void> {
    return ApiService.removeAllVoucher({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
    })
  }
  public async auditVoucher(vouchers: Voucher[], state: boolean): Promise<string[]> {
    const fails: Array<{ err: string, _id: string }> = await ApiService.auditVouchers({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      vouchers: vouchers.map((voucher) => voucher._id),
      state,
    })

    const failIds = fails.map((report) => report._id)
    vouchers
      .filter((voucher) => !failIds.includes(voucher._id))
      .forEach((voucher) => voucher.state = state ? VoucherAuditState.已审核 : VoucherAuditState.待审核)

    return fails.map((report) => {
      const voucher = vouchers.find((v) => v._id === report._id)
      return `${voucher.index}：${report.err}；`
    })
  }

  public async sortVouchersByOrder() {
    if (this.vouchers.length === 0) return

    const raws = await ApiService.sortVouchersByOrder({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      order: this.order === 'asc' ? 'desc' : 'asc',
    })
    this.innerVouchers = raws.map((raw) => new Voucher(raw))
  }

  public async sortVouchersByType() {
    const raws = await ApiService.sortVouchersByType({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
    })
    this.innerVouchers = raws.map((raw) => new Voucher(raw))
  }

  public async sortVouchersByTime() {
    const raws = await ApiService.sortVouchersByTime({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
    })
    this.innerVouchers = raws.map((raw) => new Voucher(raw))
  }

  public async splitVouchers() {
    const raws = await ApiService.combineVouchers({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
    })
    this.innerVouchers = raws.map((raw) => new Voucher(raw))
  }

  public async getAccrueReports() {
    return ApiService.getAccrueReports({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
    })
  }

  public async getParities() {
    this.innerParities = await ApiService.getParities({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
    })
  }

  public async updateAccrueReports(accrues: any[]) {
    return ApiService.accrueCalculate({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      accrues,
    })
  }

  public async accrueConfirm() {
    const vouchers = await ApiService.accrueBuild({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
    })

    this.innerVouchers = vouchers.map((voucher) => new Voucher(voucher))
  }

  public fetchVoucherTemplates(): Promise<any[]> {
    return ApiService.fetchVoucherTemplates({
      accountId: AccountService.current._id,
    })
  }

  public applyVoucherTemplate(type: string): Promise<void> {
    return ApiService.applyVoucherTemplate({
      accountId: AccountService.current._id,
      type,
    })
  }

  public createVoucherTemplate(template): Promise<void> {
    return ApiService.createVoucherTemplate(Object.assign({
      accountId: AccountService.current._id,
    }, template))
  }

  public updateVoucherTemplate(template): Promise<void> {
    return ApiService.updateVoucherTemplate(Object.assign({
      accountId: AccountService.current._id,
    }, template))
  }

  public deleteVoucherTemplate(type: string): Promise<void> {
    return ApiService.deleteVoucherTemplate({
      accountId: AccountService.current._id,
      type,
    })
  }

  public async updateResource(resource: Resource): Promise<Resource> {
    const raw = await ApiService.updateResource({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      resource,
      voucherId: resource.voucherId,
      voucherType: resource.voucherType,
    })

    return new Resource(raw, resource.voucherId, resource.voucherType)
  }

  public fetchVoucherConfig() {
    return ApiService.fetchVoucherConfig({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue
    })
  }

  public updateVoucherConfig(config) {
    return ApiService.updateVoucherConfig({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      config
    })
  }

  public refreshConfigUpdate() {
    return ApiService.refreshConfigUpdate({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue
    })
  }
  public refreshAuditFlush() {
    return ApiService.refreshAuditFlush({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue
    })
  }


  /**
   * @description 是否可以编辑凭证
   * @param {Voucher} voucher
   * @returns {boolean}
   * @memberof VoucherService
   */
  public canEditVoucher(voucher: Voucher): boolean {
    return AccountService.isCurrentIssue &&
      !voucher.isAudited &&
      AuthService.can(ActionDefinition.做账) &&
      !(voucher.sourceName === '系统生成' && '结转损益凭证结转本期损益'.includes(voucher.type))
  }
  /**
   * @description 是否可以编辑分录
   * @private
   * @memberof VoucherList
   */
  public canEditVoucherJournal(voucher): boolean {
    return this.canEditVoucher(voucher) &&
      !(voucher.sourceName === '系统生成' && '计提工资发放工资'.includes(voucher.type))
  }
}

export default makeReactive(new VoucherService())


import makeReactive from 'util/makeReactive'
import ApiService from './verificationPhone.api'


class VerificationPhone {


  public async sendPhoneAuthcode(info: { phone: string }) {
    const resData = await ApiService.sendPhoneAuthcode(info)
  }

  public async checkPhoneAuthcode(info: { verification: string,  phone: string }) {
    const d = await ApiService.checkPhoneAuthcode(info)

    return d.info === 'ok'
  }
 
}

export default makeReactive(new VerificationPhone())

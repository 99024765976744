export enum KeyCodes {
  backspace = 8,
  enter = 13,
  escape = 27,
  arrowLeft = 37,
  arrowUp = 38,
  arrowRight = 39,
  arrowDown = 40,
  delete = 46,
  num0 = 48,
  num1,
  num2,
  num3,
  num4,
  num5,
  num6,
  num7,
  num8,
  num9,
  a = 65,
  b,
  c,
  d,
  e,
  f,
  g,
  h,
  i,
  j,
  k,
  l,
  m,
  n,
  o,
  p,
  q,
  r,
  s,
  t,
  u,
  v,
  w,
  x,
  y,
  z,
  equal = 187,
}

interface KeyNamespace {
  [nsp: string]: KeyMap[]
}

interface KeyMap {
  withCtrl: boolean
  withAlt: boolean
  withShift: boolean
  keys: KeyCodes[]
  handler: (e: KeyboardEvent) => void
}

const DELIMITER = '+'

/**
 * 全局按键事件注册服务
 * 
 * @class KeyService
 */
class KeyService {
  private currentNsp: string = ''
  private namespaces: KeyNamespace = {}

  constructor() {
    window.document.addEventListener('keydown', (e) => this.broadcast(e))
  }

  /**
   * 为接下来的按键事件注册提供一个命名空间
   * 
   * 该设置主要用于不同模块间事件注册的分组操作，
   * 以便后续的off方法能够释放之前注册的回调函数；
   * 
   * @param {string} namespace 
   * @returns {this} 
   * @memberof KeyService
   */
  public of(namespace: string): this {
    this.namespaces[namespace] = []
    this.currentNsp = namespace
    return this
  }

  /**
   * 注册一个按键事件
   * 
   * @param {string} key 按键描述，eg: 'ctrl+alt+shift+d+o+g+e'
   * @param {(e: KeyboardEvent) => void} handler 事件处理回调
   * @returns {this} service实例
   * @memberof KeyService
   */
  public on(key: string, handler: (e: KeyboardEvent) => void): this {
    const keys = key.split(DELIMITER)
    const normalKeys = keys
      .filter(k => !['ctrl', 'alt', 'shift'].includes(k))
      .map(k => KeyCodes[k])

    this.namespaces[this.currentNsp].push({
      withCtrl: keys.includes('ctrl'),
      withAlt: keys.includes('alt'),
      withShift: keys.includes('shift'),
      keys: normalKeys,
      handler,
    })

    return this
  }

  /**
   * 清除特定命名空间下所有注册的事件回调函数
   * 
   * @param {string} namespace 
   * @memberof KeyService
   */
  public off(namespace: string) {
    delete this.namespaces[namespace]
  }

  private broadcast(e: KeyboardEvent) {
    Object.keys(this.namespaces).forEach(nsp => {
      this.namespaces[nsp].forEach(keymap => {
        if (keymap.withCtrl !== e.ctrlKey) return
        if (keymap.withAlt !== e.altKey) return
        if (keymap.withShift !== e.shiftKey) return

        if (keymap.keys.includes(e.keyCode)) {
          keymap.handler(e)
        }
      })
    })
  }
}

export default new KeyService()

import makeReactive from 'util/makeReactive'
import { findNdelete, arr2map } from 'util/common'
import { AccountService } from 'service'
import { Subject } from 'model/subject'
import Apiservice from './subjectService.api'

class SubjectService {
  private accountId: string = ''
  private innerSubjects: Subject[] = []
  /**
   * @description 基于科目id的subjectlist Map
   * @type {{ [key: string]: Subject }}
   * @memberof SubjectService
   */
  public innerSubjectMap: { [key: string]: Subject } = {}
  /**
   * @description 基于科目编号的subjectlist Map
   * @type {{ [key: string]: Subject }}
   * @memberof SubjectService
   */
  public innerSubjectNumMap: { [key: string]: Subject } = {}

  public get subjects(): Subject[] {
    return this.innerSubjects
  }

  public get subjectMap(): { [key: string]: Subject } {
    return this.innerSubjectMap
  }

  public async fetchSubjectsByAccount(accountId: string) {
    this.parse(await Apiservice.getSubjectList({ accountId }))
  }

  public async fetchSubjects(forceUpdate: boolean = true) {
    if (
      this.accountId === '' ||
      AccountService.current._id !== this.accountId ||
      forceUpdate
    ) {
      this.parse(await Apiservice.getSubjectList({
        accountId: AccountService.current._id,
      }))
      this.accountId = AccountService.current._id
    }
  }

  /**
   * 根据准则获取通用科目表
   *
   * @param {string} rule 准则名称
   * @memberof SubjectService
   */
  public async fetchGeneralSubjects(rule: string = '2013小企业会计准则'): Promise<Subject[]> {
    return this.parse(await Apiservice.getSubjectListByRule({
      rule,
    }))
  }

  public async create(subject: Partial<Subject>, account: any = {}): Promise<Subject> {
    const { current, total } = await Apiservice.createSubject({
      accountId: account._id || AccountService.current._id,
      issue: account.selectedIssue || AccountService.current.selectedIssue,
      subject: {
        parent: subject.parent,
        name: subject.name,
        currency: subject.currency,
        unit: subject.unit,
        items: subject.items,
      },
    })

    this.parse(total.map((raw) => new Subject(raw)))
    return new Subject(current)
  }

  public async delete(subject: Subject) {
    const { current, total } = await Apiservice.deleteSubject({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      subjectId: subject._id,
    })

    this.parse(total.map((raw) => new Subject(raw)))
  }

  /**
   * @description 清理科目
   * @returns 
   * @memberof SubjectService
   */
  public cleanup() {
    return Apiservice.cleanupSubject({
      accountId: AccountService.current._id,
    })
  }

  /**
   * @description 科目停用
   * @param {*} suspend
   * @param {Subject} subject
   * @memberof SubjectService
   */
  public suspend(suspend, subject: Subject) {
    return Apiservice.suspendSubject({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      subject: subject,
      suspend
    })
  }

  /**
   * @description 科目合并
   * @param {Subject} target 合并到的科目
   * @param {Subject} source 需要合并的科目
   * @memberof SubjectService
   */
  public async merge(target: Subject, source: Subject) {
    const { total } = await Apiservice.mergeSubject({
      accountId: AccountService.current._id,
      target,
      source
    })

    this.parse(total.map((raw) => new Subject(raw)))
  }

  public async edit({ _id, subject, name, unit, currency, items }: {
    _id: string,
    subject: string,
    name: string,
    unit: string,
    currency: string,
    items: string[],
  }, account: any = {}) {
    // TODO
    const { total } = await Apiservice.editSubject({
      accountId: account._id || AccountService.current._id,
      issue: account.selectedIssue || AccountService.current.selectedIssue,
      subject: {
        _id,
        subject,
        name,
        unit,
        isInitIssue: AccountService.current.currentIssue === AccountService.current.initIssue,
        currency,
        items,
      },
    })

    this.parse(total.map((raw) => new Subject(raw)))
  }

  public getFullSubjectName(subjectId: string) {
    if (subjectId === Subject.rootSubjectId) return ''

    try {
      const subject = this.innerSubjectMap[subjectId]
      const parentName = this.getFullSubjectName(subject.parent)
      return parentName + (parentName !== '' ? ' - ' : '') + subject.name
    } catch (error) {
      // 
    }
  }

  public findSubjectByNameSegments(segs: string[]): Subject | undefined {
    const subjectMap = this.innerSubjectMap

    function pairSubject(id: string, segNames: string[]): boolean {
      const target: Subject = subjectMap[id]
      if (target === void 0) return false

      if (target.level !== segNames.length) return false

      const [segName] = segNames.slice(-1)
      if (target.name !== segName) return false

      if (target.parent === Subject.rootSubjectId) return true
      return pairSubject(target.parent, segNames.slice(0, -1))
    }

    const [name] = segs.slice(-1)
    const targets = this.subjects.filter((s) => s.name === name)

    if (targets.length === 0) return

    return targets.find((s) => pairSubject(s._id, segs))
  }

  private parse(raws: any[]): Subject[] {
    this.innerSubjects = []
    this.innerSubjectMap = {}
    this.innerSubjectNumMap = {}
    raws.map((item, index) => {
      const raw = new Subject(item)
      this.innerSubjects.push(raw)
      this.innerSubjectMap[raw._id] = raw
      this.innerSubjectNumMap[raw.subject] = raw
    })

    // this.innerSubjects = raws.map((raw) => new Subject(raw))
    // // HACK: 以下种树的算法基于科目表已按照科目编号升序排序的前提
    // this.innerSubjectMap = arr2map(this.innerSubjects, (subject) => subject._id)
    // this.innerSubjectNumMap = arr2map(this.innerSubjects, (subject) => subject.subject)
    return this.innerSubjects
  }
}

export default makeReactive(new SubjectService())

// ----------------start标准版-------------------
const v4 = {
  local: 'http://dev.tag.jfjun.com',
  develop: 'http://dev.tag.jfjun.com',
  release: 'http://dev.tag.jfjun.com',
  product: 'http://api.jfjun.com',
};

// 新版内容标注
const tag = {
  local: 'http://dev.tag.jfjun.com',
  develop: 'http://dev.tag.jfjun.com',
  release: 'http://tag.jfjun.com',
  product: 'http://tag.jfjun.com',
};

const file = {
  local: 'http://dev.file.jfjun.com',
  develop: 'http://dev.file.jfjun.com',
  release: 'http://test.file.jfjun.com',
  product: 'http://file.jfjun.com',
};

const websocket = {
  local: 'dev.auth.jfjun.com',
  develop: 'dev.auth.jfjun.com',
  release: 'test.auth.jfjun.com',
  product: 'auth.jfjun.com',
};

const community = {
  local: 'http://dev.nodebb.manage.jfjun.com',
  develop: 'http://dev.nodebb.manage.jfjun.com',
  product: 'https://sq.jfjun.com',
};
const communitydomain = {
  local: '.jfjun.com',
  develop: '.jfjun.com',
  product: '.jfjun.com',
};
const communitywebsocket = {
  local: 'dev.nodebb.manage.jfjun.com',
  develop: 'dev.nodebb.manage.jfjun.com',
  release: 'sq.jfjun.com',
  product: 'sq.jfjun.com',
};
const communityapi = {
  local: 'http://dev.nodebb.manage.jfjun.com/api/',
  develop: 'http://dev.nodebb.manage.jfjun.com/api/',
  release: 'https://sq.jfjun.com/api/',
  product: 'https://sq.jfjun.com/api/',
};
// ----------------end标准版-------------------

// ----------------start个人极速版-------------------
const protocol = window.document.location.protocol + '//';
const wxCallbackUrl = {
  local: '127.0.0.1',
  develop: 'dev.js.jfjun.com',
  product: 'js.jfjun.com',
};

//钉钉的corpId
const corpId = {
  local: 'dingba66127ea7861aa335c2f4657eb6378f',
  develop: 'dingba66127ea7861aa335c2f4657eb6378f',
  product: 'dingba66127ea7861aa335c2f4657eb6378f',
};

const wxAppid = {
  local: 'wxfa8445b7638e0c4a',
  develop: 'wxfa8445b7638e0c4a',
  product: 'wxc9b96b63c1b46d50',
};

//个人版活动页注册
const wxCallbackUrl_HD = {
  local: 'mobile.99shuzi.com',
  develop: 'mobile.99shuzi.com',
  product: 'mobile.99shuzi.com',
};

const wxAppid_HD = {
  local: 'wxf7c529db9fcc232a',
  develop: 'wxf7c529db9fcc232a',
  product: 'wxf7c529db9fcc232a',
};

const v4cs = {
  local: 'http://cw4dev.jfjun.com',
  develop: 'http://cw4dev.jfjun.com',
  release: 'http://cw4dev.jfjun.com',
  product: 'http://api.jfjun.com',
};

const v5_mobile_api = {
  local: 'http://101.37.253.214:3331',
  develop: 'http://101.37.253.214:3331',
  release: 'http://118.178.139.119:3331',
  product: 'http://118.178.139.119:3331',
};

const filecs = {
  local: protocol + 'file.dev.js.jfjun.com',
  develop: protocol + 'file.dev.js.jfjun.com',
  release: protocol + 'test.file.js.jfjun.com',
  product: protocol + 'file.js.jfjun.com',
};

const websocketcs = {
  local: protocol + 'auth.dev.js.jfjun.com',
  develop: protocol + 'auth.dev.js.jfjun.com',
  release: protocol + 'test.auth.js.jfjun.com',
  product: protocol + 'auth.js.jfjun.com',
};

const communitycs = {
  local: 'http://dev.nodebb.manage.jfjun.com',
  develop: 'http://dev.nodebb.manage.jfjun.com',
  product: protocol + 'sq.jfjun.com',
};
const communitydomaincs = {
  local: 'jfjun.com',
  develop: 'jfjun.com',
  product: 'jfjun.com',
};
const communitywebsocketcs = {
  local: 'dev.nodebb.manage.jfjun.com',
  develop: 'dev.nodebb.manage.jfjun.com',
  release: 'sq.jfjun.com',
  product: protocol + 'sq.jfjun.com',
};

const communityapics = {
  local: 'http://dev.nodebb.manage.jfjun.com/api/',
  develop: 'http://dev.nodebb.manage.jfjun.com/api/',
  release: 'http://sq.jfjun.com/api/',
  product: protocol + 'sq.jfjun.com/api/',
};
// ----------------end个人极速版-------------------

// ----------------start钉钉商城个人极速版-------------------
const protocol_ding = window.document.location.protocol + '//';
const wxCallbackUrl_ding = {
  local: '127.0.0.1',
  develop: 'dev.ding.jfjun.com',
  product: 'ding.jfjun.com',
};
// 钉钉的corpId
const corpId_ding = {
  local: 'dingba66127ea7861aa335c2f4657eb6378f',
  develop: 'dingba66127ea7861aa335c2f4657eb6378f',
  product: 'dingba66127ea7861aa335c2f4657eb6378f',
};
const wxAppid_ding = {
  local: 'wxfa8445b7638e0c4a',
  develop: 'wxfa8445b7638e0c4a',
  product: 'wxc9b96b63c1b46d50',
};

// 个人版活动页注册
const wxCallbackUrl_HD_ding = {
  local: 'mobile.99shuzi.com',
  develop: 'mobile.99shuzi.com',
  product: 'mobile.99shuzi.com',
};

const wxAppid_HD_ding = {
  local: 'wx0b95dce874e229f2',
  develop: 'wx0b95dce874e229f2',
  product: 'wx0b95dce874e229f2',
};

const v4_ding = {
  local: 'http://cw4dev.jfjun.com',
  develop: 'http://cw4dev.jfjun.com',
  release: 'http://cw4dev.jfjun.com',
  product: 'http://api.jfjun.com',
};

const v5_mobile = {
  local: protocol + 'dev.auth.mobile.99shuzi.com',
  develop: protocol + 'dev.auth.mobile.99shuzi.com',
  release: protocol + 'auth.mobile.99shuzi.com',
  product: protocol + 'auth.mobile.99shuzi.com',
};

const file_ding = {
  local: protocol + 'file.dev.ding.jfjun.com',
  develop: protocol + 'file.dev.ding.jfjun.com',
  release: protocol + 'test.file.ding.jfjun.com',
  product: protocol + 'file.ding.jfjun.com',
};

const websocket_ding = {
  local: protocol + 'auth.dev.js.jfjun.com',
  develop: protocol + 'auth.dev.js.jfjun.com',
  release: protocol + 'test.auth.ding.jfjun.com',
  product: protocol + 'auth.ding.jfjun.com',
};

const community_ding = {
  local: 'http://dev.nodebb.manage.jfjun.com',
  develop: 'http://dev.nodebb.manage.jfjun.com',
  product: protocol + 'sq.jfjun.com',
};
const communitydomain_ding = {
  local: 'jfjun.com',
  develop: 'jfjun.com',
  product: 'jfjun.com',
};
const communitywebsocket_ding = {
  local: 'dev.nodebb.manage.jfjun.com',
  develop: 'dev.nodebb.manage.jfjun.com',
  release: 'sq.jfjun.com',
  product: protocol + 'sq.jfjun.com',
};

const communityapi_ding = {
  local: 'http://dev.nodebb.manage.jfjun.com/api/',
  develop: 'http://dev.nodebb.manage.jfjun.com/api/',
  release: 'http://sq.jfjun.com/api/',
  product: protocol + 'sq.jfjun.com/api/',
};
// ----------------end钉钉商城个人极速版-------------------
const config = {
  user: {
    expired: 1000 * 60 * 60 * 24,
  },
  v4: {
    baseUrl: v4[ENV],
  },
  tag: {
    baseUrl: tag[ENV],
  },
  file: {
    baseUrl: file[ENV],
  },
  v5_mobile: {
    baseUrl: v5_mobile[ENV],
  },

  v5_mobile_api: {
    baseUrl: v5_mobile_api[ENV],
  },

  websocket: {
    auth: websocket[ENV],
  },
  community: {
    baseUrl: community[ENV],
    domain: communitydomain[ENV],
    websocket: communitywebsocket[ENV],
    api: communityapi[ENV],
  },
  wxCallbackUrl: {
    baseUrl: wxCallbackUrl[ENV],
  },
  wxAppid: wxAppid[ENV],
  wxCallbackUrl_HD: wxCallbackUrl_HD[ENV],

  wxAppid_HD: wxAppid_HD[ENV],
  corpId: corpId[ENV],
};

// 个人极速版版本
const configcs = {
  user: {
    expired: 1000 * 60 * 60 * 24,
  },
  v4: {
    baseUrl: v4cs[ENV],
  },
  tag: {
    baseUrl: tag[ENV],
  },
  file: {
    baseUrl: filecs[ENV],
  },
  v5_mobile: {
    baseUrl: v5_mobile[ENV],
  },

  v5_mobile_api: {
    baseUrl: v5_mobile_api[ENV],
  },

  websocket: {
    auth: websocketcs[ENV],
  },
  community: {
    baseUrl: communitycs[ENV],
    domain: communitydomaincs[ENV],
    websocket: communitywebsocketcs[ENV],
    api: communityapics[ENV],
  },
  wxCallbackUrl: {
    baseUrl: wxCallbackUrl[ENV],
  },
  wxAppid: wxAppid[ENV],

  wxCallbackUrl_HD: wxCallbackUrl_HD[ENV],

  wxAppid_HD: wxAppid_HD[ENV],

  corpId: corpId[ENV],
};

// 钉钉版本
const configding = {
  user: {
    expired: 1000 * 60 * 60 * 24,
  },
  v4: {
    baseUrl: v4_ding[ENV],
  },
  v5_mobile: {
    baseUrl: v5_mobile[ENV],
  },
  tag: {
    baseUrl: tag[ENV],
  },
  file: {
    baseUrl: file_ding[ENV],
  },
  websocket: {
    auth: websocket_ding[ENV],
  },
  community: {
    baseUrl: community_ding[ENV],
    domain: communitydomain_ding[ENV],
    websocket: communitywebsocket_ding[ENV],
    api: communityapi_ding[ENV],
  },
  wxCallbackUrl: {
    baseUrl: wxCallbackUrl_ding[ENV],
  },
  wxAppid: wxAppid_ding[ENV],

  wxCallbackUrl_HD: wxCallbackUrl_HD_ding[ENV],

  wxAppid_HD: wxAppid_HD_ding[ENV],
  corpId: corpId_ding[ENV],
};
let conf = config;
if (USER_ENV === 'cs') {
  conf = configcs;
} else if (USER_ENV === 'ding') {
  conf = configding;
}
export default conf;

import { ApiService as service, ApiServiceBase, ApiCallingFn } from './apiService'

interface ApiService extends ApiServiceBase {
  uploadFile: ApiCallingFn
}

const ApiService = service as ApiService
ApiService.define([
  { type: 'file', name: 'uploadFile', path: 'file/upload' }
])

export default ApiService
import { CreateElement, VNode } from 'vue'
import idGen from '../idgen.util'

export const enum NoticeType {
  info = 'info',
  done = 'done',
  warn = 'warn',
  error = 'error'
}

type NoticeOptions = {
  type?: NoticeType,
  title?: string,
  msg?: string,
  showClose?: boolean,
  autoClose?: boolean,
  expired?: number,
  operations?: NoticeAction[],
  render?: (h: CreateElement) => VNode | VNode[]
}

export class Notice {
  /**
   * 绑定视图的唯一标识
   * 
   * @private
   * @type {string}
   * @memberof Notice
   */
  public key: string = idGen()
  /**
   * 判断当前元素是否已被hover
   * 
   * @private
   * @type {boolean}
   * @memberof Notice
   */
  public isHover: boolean = false

  /**
   * 通知视图该通知对应的元素是否已被移除
   * 
   * @private
   * @type {boolean}
   * @memberof Notice
   */
  public isDeleted: boolean = true

  /**
   * 通知的类型
   * 
   * @type {NoticeType}
   * @memberof Notice
   */
  public type: NoticeType = NoticeType.info


  /**
   * 通知的标题
   * 
   * @type {string}
   * @memberof Notice
   */
  public title: string = ''

  /**
   * 通知的消息内容
   * 
   * @type {string}
   * @memberof Notice
   */
  public msg: string = ''

  /**
   * 是否呈现关闭按扭
   * 
   * @type {boolean}
   * @memberof Notice
   */
  public showClose: boolean = true

  /**
   * 是否自动关闭
   * 
   * @type {boolean}
   * @memberof Notice
   */
  public autoClose: boolean = true

  /**
   * 自动关闭的延迟时间，仅当autoClose为true时有效，单位为毫秒
   * 
   * @type {number}
   * @memberof Notice
   */
  public expired: number = 10000

  /**
   * 通知的自定义操作组
   * 
   * @type {NoticeAction[]}
   * @memberof Notice
   */
  public operations: NoticeAction[] = []

  /**
   * 通知内容自定义渲染函数
   * 
   * 注：若render与msg同时出现，则仅渲染msg
   * 
   * @memberof Notice
   */
  public render: (h: CreateElement) => VNode | VNode[]

  /**
   * 通知关闭时的回调
   * 
   * @memberof Notice
   */
  public onClosed: () => void

  constructor(options: NoticeOptions) {
    Object.assign(this, options)
  }

  close() {
    this.onClosed && this.onClosed()
  }
}

type NoticeActionOptions = {
  title: string
  payload?: any
  handler: (payload: any) => void
}

export class NoticeAction {
  public title: string = ''
  public payload: any = null
  public handler: (payload: any) => void

  constructor(options: NoticeActionOptions) {
    Object.assign(this, options)
  }

  onclick() {
    this.handler && this.handler(this.payload)
  }

  
}
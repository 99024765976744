import Vue, { PluginObject } from 'vue'
import { Notice, NoticeType } from './notice'
import { Workflow } from '../workflow.util'

interface INotify {
  /**
   * 增加一条通知
   * 
   * @memberof INotify
   */
  add(notice: Notice): Promise<any>

  /**
   * 移除一条通知
   * 
   * @memberof INotify
   */
  remove(notice: Notice): Promise<any>

  /**
   * 增加一条普通通知
   * 
   * @param {string} msg 通知内容
   * @returns {Promise<any>} 
   * @memberof INotify
   */
  info(msg: string): Promise<any>

  /**
   * 增加一条普通通知
   * 
   * @param {Notice} option 通知对象
   * @returns {Promise<any>} 
   * @memberof INotify
   */
  info(option: Notice): Promise<any>

  /**
   * 增加一条成功通知
   * 
   * @param {string} msg 通知内容
   * @returns {Promise<any>} 
   * @memberof INotify
   */
  done(msg: string): Promise<any>

  /**
   * 增加一条成功通知
   * 
   * @param {Notice} option 通知对象
   * @returns {Promise<any>} 
   * @memberof INotify
   */
  done(option: Notice): Promise<any>

  /**
   * 增加一条警告通知
   * 
   * @param {string} msg 通知内容
   * @returns {Promise<any>} 
   * @memberof INotify
   */
  warn(msg: string | Error): Promise<any>

  /**
   * 增加一条警告通知
   * 
   * @param {Notice} option 通知对象
   * @returns {Promise<any>} 
   * @memberof INotify
   */
  warn(option: Notice): Promise<any>

  /**
   * 增加一条警告通知
   * 
   * @param {string} msg 通知内容
   * @returns {Promise<any>} 
   * @memberof INotify
   */
  error(msg: string | Error): Promise<any>

  /**
   * 增加一条警告通知
   * 
   * @param {Notice} option 通知对象
   * @returns {Promise<any>} 
   * @memberof INotify
   */
  error(option: Notice): Promise<any>
}

declare module "vue/types/vue" {
  interface Vue {
    $notify: INotify
  }
}

const notify: INotify = {
  async add(notice: Notice): Promise<any> {
    return Workflow.start('notice.add', notice)
  },
  async remove(notice: Notice): Promise<any> {
    return Workflow.start('notice.remove', notice)
  },
  async info(content: string | Notice): Promise<any> {
    if (typeof content === 'string') {
      return Workflow.start('notice.add', new Notice({ type: NoticeType.info, msg: content }))
    } else {
      return Workflow.start('notice.add', Object.assign(content, { type: NoticeType.info }))
    }
  },
  async done(content: string | Notice): Promise<any> {
    if (typeof content === 'string') {
      return Workflow.start('notice.add', new Notice({ type: NoticeType.done, msg: content }))
    } else {
      return Workflow.start('notice.add', Object.assign(content, { type: NoticeType.done }))
    }
  },
  async warn(content: string | Error | Notice): Promise<any> {
    const option = new Notice({
      type: NoticeType.warn,
      autoClose: true,
      expired: 10000,
    })

    if (typeof content === 'string') {
      option.msg = content
    } else if (content instanceof Error) {
      option.msg = content.message
      console.warn(content)
    } else {
      Object.assign(content, option)
    }

    return Workflow.start('notice.add', option)
  },
  async error(content: string | Error | Notice): Promise<any> {
    const option = new Notice({
      type: NoticeType.error,
      title: '操作失败',
      autoClose: true,
      expired: 10000,
    })

    if (typeof content === 'string') {
      option.msg = content
    } else if (content instanceof Error) {
      option.msg = content.message
      console.error(content)
    } else {
      Object.assign(content, option)
    }

    return Workflow.start('notice.add', option)
  },
}

const installer: PluginObject<any> = {
  install(vue: typeof Vue): void {
    vue.prototype.$notify = notify
  }
}

export default installer
import { ApiService as service, ApiServiceBase, ApiCallingFn } from './apiService'

interface ApiService extends ApiServiceBase {
  fetchBlacklist: ApiCallingFn
}

const ApiService = service as ApiService
ApiService.define([
  { type: 'json', name: 'fetchBlacklist', path: 'account/deny' },
])

export default ApiService

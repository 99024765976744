import { findNdelete } from 'util/common'
import makeReactive from 'util/makeReactive'
import { AccountService, UserService } from 'service'
import ApiService from './metaService.api'
import { Resource } from 'model/resource'
import {
  Meta,
  MetaHint,
  DEFAULT_METAHINT_银行数据,
  DEFAULT_METAHINT_进项数据,
  DEFAULT_METAHINT_销项数据,
  DEFAULT_METAHINT_费用数据,
  DEFAULT_METAHINT_工资数据,
  DEFAULT_METAHINT,
} from 'model/meta'

interface Category {
  name: string
  number: number,
  source: string
}

class MetaService {
  // 业务名称列表
  public transactions: string[] = []
  // 业务类型名称列表
  public categories: Category[] = []
  public category: Partial<Category> = {}
  // 业务凭证模板
  public formulas: any[] = []

  private accountId: string = ''
  private metaHints: { [key: string]: MetaHint } = {}

  public getMetaHint(transaction: string, category: string) {
    if (this.metaHints[transaction]) {
      return this.metaHints[transaction]
    } else if (transaction === '未知') {
      switch (category) {
        case '银行数据':
          return DEFAULT_METAHINT_银行数据
        case '进项数据':
          return DEFAULT_METAHINT_进项数据
        case '销项数据':
          return DEFAULT_METAHINT_销项数据
        case '费用数据':
          return DEFAULT_METAHINT_费用数据
        case '工资数据':
          return DEFAULT_METAHINT_工资数据
      }
    }

    return DEFAULT_METAHINT
  }

  public clearMetaHints(transacation?: string) {
    if (transacation) {
      delete this.metaHints[transacation]
    } else {
      this.metaHints = {}
    }
  }
  public fetchMetalist(category?: string, source?: string): Promise<Meta[]> {
    return ApiService.fetchMetalist({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      category,
      source,
    })
  }

  public async fetchMeta(resourceId: string, resourceType: string): Promise<Resource> {
    return this.parse(await ApiService.fetchMetaDetail({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      metadataId: resourceId,
      category: resourceType,
    }))
  }

  public async fetchMetaHint(transacation: string, category: string): Promise<MetaHint> {
    if (this.accountId !== AccountService.current._id) {
      this.metaHints = {}
      this.accountId = AccountService.current._id
    }

    if (this.metaHints[transacation]) {
      return this.metaHints[transacation]
    } else if (transacation === '未知') {
      switch (category) {
        case '银行数据':
          return DEFAULT_METAHINT_银行数据
        case '进项数据':
          return DEFAULT_METAHINT_进项数据
        case '销项数据':
          return DEFAULT_METAHINT_销项数据
        case '费用数据':
          return DEFAULT_METAHINT_费用数据
        case '工资数据':
          return DEFAULT_METAHINT_工资数据
        default:
          return DEFAULT_METAHINT
      }
    }

    const hint = await ApiService.fetchMetaHint({
      accountId: AccountService.current._id,
      transactionType: transacation,
      category,
    })
    Object.assign(this.metaHints, hint)

    return hint[transacation]
  }

  /**
   * @description 扫描票据取消识别编辑凭证操作
   * @param {*} info
   * @returns
   * @memberof MetaService
   */
  public async editInvoiceFile(info) {
    return this.parse(await ApiService.editInvoiceFile({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      info,
    }))
  }
  public async scannerDelete(ids: string[]) {
    return ApiService.scannerDelete({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      ids,
    })
  }
  public async fetchTransactions(forceRefresh: boolean = false) {
    if (
      forceRefresh ||
      this.accountId !== AccountService.current._id ||
      this.transactions.length === 0
    ) {
      const transactions = await ApiService.fetchTransactions({
        accountId: AccountService.current._id,
        category: null,
      })
      this.transactions = ['未知'].concat(transactions)
      this.accountId = AccountService.current._id
    }

    return this.transactions
  }

  public async fetchSourceOptions() {
    return ApiService.fetchSourceOptions({
      clientName: UserService.current.client,
    })
  }

  public async fetchCategories() {
    this.categories = await ApiService.fetchCategories({
      accountId: AccountService.current._id,
    })
    return this.categories
  }

  public async updateCategory(categoryInfo) {
    return ApiService.updateCategory({
      accountId: AccountService.current._id,
      categoryInfo,
    })
  }

  public async deleteCategory(name: string) {
    await ApiService.deleteCategory({
      accountId: AccountService.current._id,
      name,
    })

    findNdelete(this.categories, (cat) => cat.name === name)
  }

  public createMeta(meta: Meta, category: string): Promise<Meta[]> {
    if (meta.业务 === '') meta.业务 = '未知'

    return ApiService.createMeta({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      category,
      metadata: meta,
    })
  }

  public async updateFormulaInfo(formula: any) {
    const newFormula = await ApiService.updateFormulaInfo({
      accountId: AccountService.current._id,
      transactionInfo: formula,
    })
    await this.fetchTransactions(true)

    return newFormula
  }

  public async updateResource(resource: Resource): Promise<Resource> {
    if (resource.metadata.业务 === '') resource.metadata.业务 = '未知'

    const metadata = Object.assign({}, resource.metadata, { detail: resource.detail })
    return this.parse(await ApiService.updateMeta({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      category: resource.metadata.category,
      metadata,
    }))
  }

  public async updateMeta(metadata: Meta): Promise<Meta> {
    const { metadata: m } = await ApiService.updateMeta({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      category: metadata.category,
      metadata,
    })
    return m
  }

  public updateMetaTransaction(metadata: Meta) {
    return ApiService.updateMeta({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      category: metadata.category,
      metadata,
    })
  }

  public deleteMeta(meta: Meta): Promise<Meta[]> {
    return ApiService.deleteMeta({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      category: meta.category,
      metadataIds: [meta._id],
    })
  }

  public async recycleMeta(meta: Meta): Promise<Meta> {
    const { metadata: m } = await ApiService.updateMeta({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      category: meta.category,
      metadata: Object.assign({}, meta, {
        deleted: false,
        isRepeat: false,
        promptMsg: '',
      }),
    })

    return m
  }

  public async fetchFormulas() {
    this.formulas = await ApiService.fetchFormulas({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
    })
    return this.formulas
  }

  public async updateFormula(category: string, name: string, entries: any[]) {
    await ApiService.updateFormula({
      accountId: AccountService.current._id,
      category,
      name,
      entries,
      issue: AccountService.current.selectedIssue,
    })
    delete this.metaHints[name]
  }

  public async deleteFormula(name: string) {
    return ApiService.deleteFormula({
      accountId: AccountService.current._id,
      name,
    })
  }

  public async transferFormulas(formulas: any[]) {
    await ApiService.transferFormulas({
      accountId: AccountService.current._id,
      transaction: formulas,
      issue: AccountService.current.selectedIssue,
    })
    await this.fetchTransactions(true)
  }

  /**
   * @description 批量删除模板集
   * @param {any[]} formulas
   * @memberof MetaService
   */
  public async templateDelete(names: any[]) {
    await ApiService.templateDelete({
      accountId: AccountService.current._id,
      names
    })
    await this.fetchTransactions(true)
  }

  public async probabilityCompensate(id: string) {
    await ApiService.probabilityCompensate({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      ids: [id],
    })
  }

  public fetchCommonFormulas() {
    return ApiService.fetchCommonFormulas({
      accountId: AccountService.current._id,
    })
  }

  public fetchCommonCategories() {
    return ApiService.fetchCommonCategories({
      accountId: AccountService.current._id,
    })
  }

  /**
   * 批量修改业务
   *
   * @param {string[]} ids
   * @param {string} transaction
   * @param {string} category
   * @param {string} costDetail
   * @returns
   * @memberof MetaService
   */
  public batchEditMetadata(meta: Meta) {
    return ApiService.batchEditMetadata({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      ids: meta.ids,
      category: meta.category,
      costDetail: meta.costDetail,
      transaction: meta.transaction,
    })
  }

  /**
   * @description 重新查询商品明细
   * @memberof MetaService
   */
  public async metadataRequeryDetail(category) {
    const { body } = await ApiService.requeryDetail({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      category
    })
    return body
  }

  private parse(raw: any): Resource {
    const foo = {
      _id: raw.metadata._id,
      src: raw.metadata.src,
      deleted: raw.metadata.deleted,
      invalid: raw.metadata.invalid,
      duplicated: raw.metadata.isRepeat,
      category: raw.metadata.category,
      entries: raw.entries || [],
      detail: raw.metadata.detail,
      type: raw.metadata.业务,
    }
    const { voucherId, voucherType, voucherNumber } = raw

    delete raw.metadata.src
    delete raw.metadata.detail

    return new Resource(
      Object.assign(foo, { metadata: raw.metadata }),
      voucherId,
      voucherType,
      voucherNumber
    )
  }
}

export default makeReactive(new MetaService())

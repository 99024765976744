const ROOT_SUBJECT = '000000cd03f834085d950000'

const DEFS_CURRENCIES = [
  { type: 'CNY', title: 'CNY-人民币' },
  { type: 'USD', title: 'USD-美元' },
  { type: 'EUR', title: 'EUR-欧元' },
  { type: 'JPY', title: 'JPY-日元' },
  { type: 'HKD', title: 'HKD-港元' },
  { type: 'GBP', title: 'GBP-英镑' },
  { type: 'AUD', title: 'AUD-澳元' },
  { type: 'NZD', title: 'NZD-新西兰元' },
  { type: 'SGD', title: 'SGD-新加坡元' },
  { type: 'CHF', title: 'CHF-瑞士法郎' },
  { type: 'CAD', title: 'CAD-加拿大元' },
  { type: 'MYR', title: 'MYR-马来西亚林吉特' },
  { type: 'RUB', title: 'RUB-俄罗斯卢布' },
  { type: 'KRW', title: 'KRW-韩元' },
  { type: 'INR', title: 'INR-印度卢比' },
  { type: 'IDR', title: 'IDR-印尼盾' },
  { type: 'SEK', title: 'SEK-瑞典克朗' },
  { type: 'TWD', title: 'TWD-新台币' }
]

/**
 * 科目
 * 
 * @export
 * @class Subject
 */
export class Subject {
  /**
   * id
   * 
   * @type {string}
   * @memberof Subject
   */
  _id: string = ''

  /**
   * 币种
   * 
   * @type {string}
   * @memberof Subject
   */
  currency: string = 'CNY'

  /**
   * 借贷方向
   * 
   * @type {(-1 | 1)}
   * @memberof Subject
   */
  direction: -1 | 1 = 1

  /**
   * 是否为叶子节点
   * 
   * @type {boolean}
   * @memberof Subject
   */
  isLeaf: boolean = true

  /**
   * 核算类型
   * 
   * @type {string[]}
   * @memberof Subject
   */
  items: string[] = []

  /**
   * 级别
   * 
   * @type {number}
   * @memberof Subject
   */
  level: number = 1

  /**
   * 名称
   * 
   * @type {string}
   * @memberof Subject
   */
  name: string = ''

  /**
   * 上级科目id
   * 
   * @type {string}
   * @memberof Subject
   */
  parent: string = ROOT_SUBJECT

  /**
   * 简拼
   * 
   * @type {string}
   * @memberof Subject
   */
  pinyin: string = ''

  /**
   * 税率
   * 
   * @type {number}
   * @memberof Subject
   */
  rate: number = 0

  /**
   * 编号
   * 
   * @type {string}
   * @memberof Subject
   */
  subject: string = ''

  /**
   * 是否为系统创建的科目
   * 
   * @type {boolean}
   * @memberof Subject
   */
  system: boolean = false

  /**
   * 是否为涉税科目
   * 
   * @type {boolean}
   * @memberof Subject
   */
  tax: boolean = false

  /**
   * 数量单位
   * 
   * @type {string}
   * @memberof Subject
   */
  unit: string = ''

  /**
   * 当前科目子集的展开/关闭状态
   * 
   * @readonly
   * @type {Boolean}
   * @memberof Subject
   */
  active: Boolean = true

  /**
   * 是否展开其子集的状态
   * 
   * @type {Boolean}
   * @memberof Subject
   */
  switcherStatus: Boolean = true

  /**
   * 科目类型名称
   *
   * @type {string}
   * @memberof Subject
   */
  ruleType: string = ''

  // 判断是否已经启用
  time: string = ''
  endTime: string = ''

  /**
   * @description 科目是否是停用状态
   * @type {boolean}
   * @memberof Subject
   */
  suspend: boolean = false

  constructor (raw?: any) {
    Object.assign(this, raw)
  }

  /**
   * 根科目的虚拟id
   * 
   * @readonly
   * @static
   * @memberof Subject
   */
  static get rootSubjectId() {
    return ROOT_SUBJECT
  }

  /**
   * 支持的币种列表
   * 
   * @readonly
   * @static
   * @memberof Subject
   */
  static get currencyList() {
    return DEFS_CURRENCIES
  }

  /**
   * 获取借贷方向名称
   * 
   * @readonly
   * @type {string}
   * @memberof Subject
   */
  get directionName(): string {
    if (this.direction === 1) return '借'
    if (this.direction === -1) return '贷'
    return '?'
  }
}
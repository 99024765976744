import {
  ApiService as service,
  ApiServiceBase,
  ApiCallingFn,
} from 'service/apiService';

interface ApiService extends ApiServiceBase {
  login: ApiCallingFn;
  updatePassword: ApiCallingFn;
  editUserInfo: ApiCallingFn;
  getAccess_token_Wx: ApiCallingFn;
  getRemind_userService: ApiCallingFn;
  editPersonalPassword: ApiCallingFn;
  editPersonalName: ApiCallingFn;
  editBindInfo_personalCenterEpsonProxy: ApiCallingFn;
  get_ticket: ApiCallingFn;
}

const ApiService = service as ApiService;
ApiService.define([
  { type: 'json', name: 'login', path: 'user/login' },
  { type: 'json', name: 'updatePassword', path: 'user/editPassword' },
  { type: 'json', name: 'editUserInfo', path: 'user/editInfoOrPassword' },
  { type: 'json', name: 'getRemind_userService', path: 'deal/get/remind' },
  {
    type: 'json',
    name: 'editPersonalPassword',
    path: 'account/personal/password',
  },
  {
    type: 'json',
    name: 'editPersonalName',
    path: 'account/personal/name',
  },
  {
    type: 'json',
    name: 'editBindInfo_personalCenterEpsonProxy',
    path: 'account/epson/editInfo',
  },
  {
    type: 'json',
    name: 'get_ticket',
    path: 'personal/ticket/get',
  },
  {
    type: 'http',
    name: 'getAccess_token_Wx',
    path: 'https://api.weixin.qq.com/sns/oauth2/access_token',
  },
  {
    type: 'http',
    name: 'getUserinfo_Wx',
    path: 'https://api.weixin.qq.com/sns/userinfo',
  },
 
  
]);

export default ApiService;

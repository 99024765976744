import ApiService from './uploadService.api'
import AccountService from './accountService'
import JwtService from './jwtService'
import { obj2FormData } from 'util/common'

/**
 * 文件信息描述
 *
 * @interface FileInfo
 */
interface FileInfo {
  /**
   * 地址
   *
   * @type {string}
   * @memberof FileInfo
   */
  src: string

  /**
   * MD5
   *
   * @type {string}
   * @memberof FileInfo
   */
  md5: string

  /**
   * 缩略图
   *
   * @type {string}
   * @memberof FileInfo
   */
  thumbnail: string
}

/**
 * 文件上传服务
 *
 * 用于打开文件通用对话框，以及回传用户选择的文件对象
 *
 * @class UploadService
 */
class UploadService {
  private input: HTMLInputElement
  private resolveHandler: (files: File[]) => void

  constructor() {
    this.input = window.document.createElement('input')
    this.input.style.display = 'none'

    this.input.setAttribute('type', 'file')
    this.input.addEventListener('change', (e) => this.onFileUpdated())

    window.document.body.appendChild(this.input)
  }

  /**
   * 打开通用文件对话框
   *
   * @param {string} accepts 允许上传的文件类型
   * @param {boolean} [multiple=false] 是否允许上传多个文件
   * @returns {Promise<FileInfo>} 包含原生file对象的Promise
   * @memberof UploadService
   */
  public async chooseFiles(accepts: string, multiple: boolean = false): Promise<File[]> {
    this.input.setAttribute('accept', accepts)
    this.input.setAttribute('multiple', multiple.toString())

    return new Promise<File[]>((resolve) => {
      this.input.click()
      this.resolveHandler = resolve
    })
  }

  /**
   * 上传文件至服务器
   *
   * @param {File[]} files 原生file对象数组
   * @returns {Promise<FileInfo>} 包含文件描述信息的Promise
   * @memberof UploadService
   */
  public async upload(files: File[], accountId?: string, issue?: string): Promise<FileInfo> {
    const token = await JwtService.loadJwt()

    return ApiService.uploadFile(obj2FormData({
      accountId: accountId || AccountService.current._id,
      issue: issue || AccountService.current.selectedIssue,
      token,
      // TODO: 尚未支持多个文件上传
      file: files[0],
    }))
  }

  /**
   * 验证上传文件格式
   *
   * @param {File[]} files
   * @param {string} formats
   * @returns
   * @memberof UploadService
   */
  public verifyFileType(files: File[], formats: string) {
    let verify = true
    files.forEach((item) => {
      if (formats.split(',').findIndex((_i) => {
        const typelist = item.name.split('.')
        const type = typelist[typelist.length - 1]
        return _i === type.toLowerCase()
      }) < 0) {
        verify = false
      }
    })
    return verify
  }

  /**
   * 上传文件至服务器
   *
   * 注：与账套无关的全局文件
   *
   * @param {File[]} files 原生file对象数组
   * @returns {Promise<FileInfo>} 包含文件描述信息的Promise
   * @memberof UploadService
   */
  public async uploadGlobal(files: File[]): Promise<FileInfo> {
    const token = await JwtService.loadJwt()

    return ApiService.uploadFile(obj2FormData({
      accountId: 'global',
      issue: 'global',
      token,
      // TODO: 尚未支持多个文件上传
      file: files[0],
    }))
  }

  private onFileUpdated() {
    this.resolveHandler(Array.from(this.input.files))
    this.resolveHandler = void 0
    this.input.value = ''
  }
}

export default new UploadService()

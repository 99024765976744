/*
 * @Author: your name
 * @Date: 2020-05-29 17:06:20
 * @LastEditTime: 2020-08-12 17:32:20
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /jfjun-fsc-front/src/service/voucherService.api.ts
 */
import { ApiService as service, ApiServiceBase, ApiCallingFn } from 'service/apiService'

interface ApiService extends ApiServiceBase {
  getVouchersList: ApiCallingFn
  getVoucherDetail: ApiCallingFn
  createVoucher: ApiCallingFn
  updateVoucher: ApiCallingFn
  updateVoucherNumber: ApiCallingFn
  deleteVoucher: ApiCallingFn
  auditVouchers: ApiCallingFn
  sortVouchersByOrder: ApiCallingFn
  sortVouchersByType: ApiCallingFn
  sortVouchersByTime: ApiCallingFn
  combineVouchers: ApiCallingFn
  updateVoucherConfig: ApiCallingFn
  fetchVoucherConfig: ApiCallingFn

  getAccrueReports: ApiCallingFn
  accrueCalculate: ApiCallingFn
  accrueBuild: ApiCallingFn
  getParities: ApiCallingFn

  fetchVoucherTemplates: ApiCallingFn
  applyVoucherTemplate: ApiCallingFn
  createVoucherTemplate: ApiCallingFn
  updateVoucherTemplate: ApiCallingFn
  deleteVoucherTemplate: ApiCallingFn

  updateResource: ApiCallingFn

  fetchIndustryDetail: ApiCallingFn

  refreshConfigUpdate: ApiCallingFn

  refreshAuditFlush: ApiCallingFn

  removeAllVoucher: ApiCallingFn
}

const ApiService = service as ApiService
ApiService.define([
  { type: 'json', name: 'getVouchersList', path: 'voucher/list' },
  { type: 'json', name: 'getVoucherDetail', path: 'voucher/detail' },
  { type: 'json', name: 'createVoucher', path: 'voucher/create' },
  { type: 'json', name: 'updateVoucher', path: 'voucher/edit' },
  { type: 'json', name: 'updateVoucherNumber', path: 'voucher/number' },
  { type: 'json', name: 'deleteVoucher', path: 'voucher/delete' },
  { type: 'json', name: 'removeAllVoucher', path: 'voucher/remove' },

  { type: 'json', name: 'auditVouchers', path: 'voucher/audit' },
  { type: 'json', name: 'sortVouchersByOrder', path: 'voucher/order' },
  { type: 'json', name: 'sortVouchersByType', path: 'voucher/transaction' },
  { type: 'json', name: 'sortVouchersByTime', path: 'voucher/sortByTime' },
  { type: 'json', name: 'combineVouchers', path: 'voucher/split' },
  { type: 'json', name: 'updateVoucherConfig', path: 'voucher/config' },
  { type: 'json', name: 'fetchVoucherConfig', path: 'voucher/config' },

  { type: 'json', name: 'getAccrueReports', path: 'accrue/report' },
  { type: 'json', name: 'accrueCalculate', path: 'accrue/config' },
  { type: 'json', name: 'accrueBuild', path: 'accrue/confirm' },
  { type: 'json', name: 'getParities', path: 'accrue/parities' },

  { type: 'json', name: 'fetchVoucherTemplates', path: 'voucher/template/list' },
  { type: 'json', name: 'applyVoucherTemplate', path: 'voucher/template/use' },
  { type: 'json', name: 'createVoucherTemplate', path: 'voucher/template/add' },
  { type: 'json', name: 'updateVoucherTemplate', path: 'voucher/template/edit' },
  { type: 'json', name: 'deleteVoucherTemplate', path: 'voucher/template/delete' },

  { type: 'json', name: 'updateResource', path: 'resource/edit' },

  { type: 'json', name: 'fetchIndustryDetail', path: 'metadata/client/getTableInfo' },

  { type: 'json', name: 'refreshConfigUpdate', path: 'accrue/config/update' },
  { type: 'json', name: 'refreshAuditFlush', path: 'audit/flush' },

])

export default ApiService

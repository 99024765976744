import './printService.scss'

const DEFAULT_SLICE = 1

export const PORTRAIT_RATE = 3.59
export const LANDSCAPE_RATE = 3.59

export interface Size {
  width: any
  height: any
}

export const A4 = {
  width: 210,
  height: 297
}

export const A5 = {
  width: 148,
  height: 210
}

export enum Orientation {
  纵向 = 'portrait',
  横向 = 'landscape'
}

export function size2Print(size: Size | string | number, type: Orientation) {
  let rate = type === Orientation.纵向
    ? PORTRAIT_RATE
    : LANDSCAPE_RATE

  if (Number(size) === NaN) return
  else if (typeof size === 'object') {
    return { width: size.width * rate, height: size.height * rate }
  } else {
    return Number(size) * rate
  }
}


class PrintService {
  private site: HTMLElement
  private container: HTMLElement
  private styleTag: HTMLElement
  private pageCache: string[] = []
  private slice: number = DEFAULT_SLICE

  constructor () {
    this.initContainer()
    this.initStyle()
  }

  setLayout(size: Size, orientation: 'portrait' | 'landscape', slice: number = DEFAULT_SLICE) {
    this.slice = slice
    if (size.width != 'auto') {
      let s = ''
      if (size.width === A4.width && size.height === A4.height) {
        s = 'A4'
      } else if (size.width === A5.width && size.height === A5.height) {
        s = 'A5'
      } else {
        s = `${size.width * 0.0393701}in ${size.height * 0.0393701}`
      }
      this.styleTag.innerText = `@page{size: ${s} ${orientation};margin: 0;}`
    } else {
      this.styleTag.innerText = `@page{size: auto portrait;margin: 0;}`
    }
  }

  cacheImages(sources: string[]): Promise<void> {
    const loader = new Image()
    let index = 0

    return new Promise(resolve => {
      loader.onload = loader.onerror = () => {
        if (index === sources.length) {
          resolve()
        }

        loader.src = sources[++index]
      }

      loader.src = sources[0]
    })
  }

  cacheSegment(doc: HTMLElement) {
    this.pageCache.push(`<div class="print-page" style="height: ${100 / this.slice}%">${doc ? doc.outerHTML : ''}</div>`)
  }

  print() {
    this.container.innerHTML = this.pageCache.join('')
    this.show()

    setTimeout(() => {
      window.print()

      this.hide()
      this.flush()
    }, 100)
  }

  private initContainer() {
    this.container = window.document.createElement('div')
    this.container.classList.add('print-container')

    window.document.body.appendChild(this.container)
  }

  private initStyle() {
    this.styleTag = window.document.createElement('style')
    this.setLayout({ width: A4.width, height: A4.height }, 'portrait', 2)

    window.document.body.appendChild(this.styleTag)
  }

  private show() {
    this.getElementSite().style.display = 'none'
    this.container.style.display = 'block'
  }

  private hide() {
    this.getElementSite().style.display = 'block'
    this.container.style.display = 'none'
  }

  private flush() {
    this.pageCache = []
  }

  private getElementSite(): HTMLElement {
    return this.site || (this.site = window.document.getElementById('site'))
  }
}

export default new PrintService()
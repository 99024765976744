import { ApiService as service, ApiServiceBase, ApiCallingFn } from 'service/apiService'

interface ApiService extends ApiServiceBase {
  fetchAccounts: ApiCallingFn
  createAccount: ApiCallingFn
  importAccounts: ApiCallingFn
  deleteAccount: ApiCallingFn
  carryover: ApiCallingFn
  rollback: ApiCallingFn
  getEquilibriumInfo: ApiCallingFn
  getAccountState: ApiCallingFn<any, string>
  getAuditReports: ApiCallingFn
  editSheetYearAfter: ApiCallingFn
  fetchOweToRemind: ApiCallingFn
  getAccountDetail: ApiCallingFn
  getTemplate: ApiCallingFn
  updateAccountDetail: ApiCallingFn

  exportAccounts: ApiCallingFn
}

const ApiService = service as ApiService
ApiService.define([
  { type: 'json', name: 'fetchAccounts', path: 'account/list' },
  { type: 'json', name: 'exportAccounts', path: 'toExcel/getUrl' },
  { type: 'json', name: 'createAccount', path: 'account/create' },
  { type: 'json', name: 'importAccounts', path: 'account/batch/create' },
  { type: 'json', name: 'deleteAccount', path: 'account/delete' },
  { type: 'json', name: 'carryover', path: 'book/close' },
  { type: 'json', name: 'rollback', path: 'book/open' },
  { type: 'json', name: 'getEquilibriumInfo', path: 'equilibrium/getNoEquilibrium' },
  { type: 'json', name: 'getAccountState', path: 'account/getAccountStat' },
  { type: 'json', name: 'getAuditReports', path: 'audit/report' },
  { type: 'json', name: 'editSheetYearAfter', path: 'account/edit' },
  { type: 'json', name: 'updateAccountDetail', path: 'account/edit' },
  { type: 'json', name: 'fetchOweToRemind', path: 'deal/ac/balance' },
  { type: 'json', name: 'getAccountDetail', path: 'account/detail' },
  { type: 'json', name: 'getTemplate', path: 'metadata/template' },
])

export default ApiService

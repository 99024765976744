import Vue from "vue";
import { Component, Prop, Watch } from 'vue-property-decorator'
import './button.scss'
import '../iconfont.css'

@Component
export default class Button extends Vue {
  @Prop({ default: 'primary' })
  type: 'primary' | 'text' | 'simple' | 'icon' | 'info' | 'success' | 'warning' | 'error'

  @Prop({ default: false })
  expand: boolean

  @Prop({ default: false })
  shrink: boolean

  @Prop({ default: false })
  disabled: boolean

  @Prop({ default: false })
  ghost: boolean

  @Prop()
  icon: string

  @Prop({ default: 'normal' })
  size: 'large' | 'normal' | 'small'

  get buttonStyle() {
    return {
      'jfj-button': true,
      'jfj-button--expand': this.expand,
      'jfj-button--shrink': this.shrink || this.type === 'icon',
      'jfj-button--disabled': this.disabled
    }
  }

  get wrapperStyle() {
    return {
      'jfj-button-wrapper': true,
      [`jfj-button--${this.type}`]: true,
      'jfj-button--ghost': this.ghost,
      [`jfj-button--${this.size}`]: true
    }
  }

  get iconStyle() {
    return ['iconfont', this.icon]
  }

  onclick(e: MouseEvent) {
    e.stopPropagation()

    !this.disabled && this.$emit('click', e)
  }

  render(h) {
    return (
      <div class={this.buttonStyle} onClick={this.onclick}>
        <div class={this.wrapperStyle}>{
          this.$slots.default
            ? this.$slots.default
            : <i class={this.iconStyle}></i>
        }</div>
      </div>
    )
  }
}
import moment, { Moment } from 'moment'
/**
 * 将账期字符串转化为内部计算数值
 * 
 * @param {string} issue 账期字符串
 * @returns {number} 代表该账期的数值
 */
function issue2Num(issue: string): number {
  try {
    const [year, month] = issue.split('-')
    return parseInt(year) * 100 + parseInt(month)
  } catch (e) {
    throw new Error(`[Issue]Invalid issue: "${issue}".`)
  }
}

/**
 * 将内部计算数值转换成账期字符串
 * 
 * @param {number} num Issue内部计算数值
 * @returns {string} 账期字符串
 */
function num2IssueStr(num: number): string {
  const year = getYear(num)
  const month = getMonth(num)

  return `${year}-${month < 10 ? '0' + month : month}`
}

/**
 * 从内部计算数值中提取年份
 * 
 * @param {number} num 内部计算数值
 * @returns {number} 年份值
 */
function getYear(num: number): number {
  return Math.floor(num / 100)
}

/**
 * 从内部计算数值中提取月份
 * 
 * @param {any} num 内部计算数值
 * @returns {number} 月份值
 */
function getMonth(num): number {
  return num % 100
}

/**
 * 默认账期
 */
const DEFAULT_ISSUE_TIME = '1970-01'

/**
 * 账期对象
 * 
 * @export
 * @class Issue
 */
export default class Issue {
  /**
   * 内部计算数值，代表输入的账期字符串
   * 
   * @private
   * @type {number}
   * @memberof Issue
   */
  private innerValue: number = 0

  constructor(issue: string | number | Issue | Date = DEFAULT_ISSUE_TIME) {
    this.innerValue = Issue.parse(issue)
  }

  /**
   * 将各类账期值格式化到内部计算数值
   * 
   * @static
   * @param {(string | number | Issue | Date)} issue 代表账期的值
   * @returns {number} 内部计算数值
   * @memberof Issue
   */
  static parse(issue: string | number | Issue | Date): number {
    if (typeof issue === 'string') {
      return issue2Num(issue)
    }
    if (typeof issue === 'number') {
      return issue
    }
    if (issue instanceof Issue) {
      return issue.innerValue
    }
    if (issue instanceof Date) {
      return issue.getFullYear() * 100 + issue.getMonth() + 1
    }
  }

  /**
   * 获取指定账期的上一期
   * 
   * @static
   * @param {(string | number | Issue)} issue 指定的账期
   * @returns {Issue} 代表指定账期上一期的Issue对象
   * @memberof Issue
   */
  static last(issue: string | number | Issue): Issue {
    let num = Issue.parse(issue)

    if (getMonth(num) === 1) {
      num = num - 100 + 11
    } else {
      num = num - 1
    }

    return new Issue(num)
  }

  /**
   * 获取指定账期的下一期
   * 
   * @static
   * @param {(string | number | Issue)} issue 指定的账期
   * @returns {Issue} 代表指定账期下一期
   * @memberof Issue
   */
  static next(issue: string | number | Issue): Issue {
    let num = Issue.parse(issue)

    if (getMonth(num) === 12) {
      num = num + 100 - 11
    } else {
      num = num + 1
    }

    return new Issue(num)
  }

  /**
   * 获取指定账期n个月之后的账期
   * 
   * @static
   * @param {(string | number | Issue)} issue 指定的账期
   * @param {number} month 相加的月份
   * @returns {Issue} 结果账期
   * @memberof Issue
   */
  static add(issue: string | number | Issue, month: number): Issue {
    let result = new Issue(issue)

    for (let i = 0; i < month; i++) {
      result = Issue.next(result)
    }

    return result
  }

  /**
   * 获取指定账期n个月之前的账期
   * 
   * @static
   * @param {(string | number | Issue)} issue 指定的账期
   * @param {number} month 相减的月份
   * @returns {Issue} 结果账期
   * @memberof Issue
   */
  static subtract(issue: string | number | Issue, month: number): Issue {
    let result = new Issue(issue)

    for (let i = 0; i < month; i++) {
      result = Issue.last(result)
    }

    return result
  }

  /**
   * 判断指定账期是否处于指定期间内
   * 
   * @static
   * @param {(string | number | Issue)} start 期间的开始账期
   * @param {(string | number | Issue)} end 期间的结束账期
   * @param {(string | number | Issue)} issue 需要判断的指定账期
   * @returns {boolean} 判断结果
   * @memberof Issue
   */
  static within(
    start: string | number | Issue,
    end: string | number | Issue,
    issue: string | number | Issue
  ): boolean {
    if (Issue.diff(start, end) > 0) {
      throw new Error(`[Issue]Start issue should be before end issue, but got: Start: ${start}, End: ${end}`)
    }

    const floor = Issue.parse(start)
    const ceil = Issue.parse(end)
    const current = Issue.parse(issue)

    return current >= floor && current <= ceil
  }

  /**
   * 获取包括在指定期间内所有账期的数组
   * 
   * @static
   * @param {(string | number | Issue)} start 期间的开始账期
   * @param {(string | number | Issue)} end 期间的结束账期
   * @returns {Issue[]} 期间内所有账期的数组
   * @memberof Issue
   */
  static range(
    start: string | number | Issue,
    end: string | number | Issue
  ): Issue[] {
    if (Issue.diff(start, end) > 0) {
      throw new Error(`[Issue]Start issue should be before end issue, but got: Start: ${start}, End: ${end}`)
    }

    const results: number[] = []
    for (let i = Issue.parse(start); i <= Issue.parse(end); i++) {
      results.push(i)

      if (getMonth(i) === 12) i += 88
    }

    return results.map(num => new Issue(num))
  }

  /**
   * 计算两个账期之间相隔多少月份
   * @static
   * @param {(string | number | Issue)} start 开始账期，被减数
   * @param {(string | number | Issue)} end 结束账期，减数
   * @returns {number} 相隔的月份
   * @memberof Issue
   */
  static diff(
    start: string | number | Issue,
    end: string | number | Issue
  ): number {
    const a = Issue.parse(start)
    const b = Issue.parse(end)

    return (getYear(a) - getYear(b)) * 12 + getMonth(a) - getMonth(b)
  }

  /**
   * 判断指定字符串是否为合法的账期字符串
   * 
   * @static
   * @param {string} issue 需要判断的字符串
   * @returns {boolean} 判断结果
   * @memberof Issue
   */
  static isValidIssueStr(issue: string): boolean {
    return /^\d{4}-\d{2}/.test(issue)
  }

  /**
   * 获取当前系统账期
   * 
   * @readonly
   * @static
   * @type {Issue}
   * @memberof Issue
   */
  static get systemIssue(): Issue {
    return Issue.last(Issue.currentIssue)
  }

  /**
   * 获取当前账期
   * 
   * @readonly
   * @static
   * @type {Issue}
   * @memberof Issue
   */
  static get currentIssue(): Issue {
    return new Issue(new Date())
  }

  /**
   * 获取默认的基准账期，代表"1970-01"
   * 
   * @readonly
   * @static
   * @type {Issue}
   * @memberof Issue
   */
  static get default(): Issue {
    return new Issue(DEFAULT_ISSUE_TIME)
  }

  /**
   * 获取当前系统账期
   */
  static get currentSystemIssue(): string {
    return moment().format('YYYY-MM')
  }
  
  /**
   * 获取账期的年份
   * 
   * @readonly
   * @type {number}
   * @memberof Issue
   */
  get year(): number {
    return getYear(this.innerValue)
  }

  /**
   * 获取账期的月份
   * 
   * @readonly
   * @type {number}
   * @memberof Issue
   */
  get month(): number {
    return getMonth(this.innerValue)
  }

  /**
   * 判断该账期是否处于指定期间内
   * 
   * @param {(string | number | Issue)} start 期间的开始账期
   * @param {(string | number | Issue)} end 期间的结束账期
   * @returns {boolean} 判断结果
   * @memberof Issue
   */
  isWithin(
    start: string | number | Issue,
    end: string | number | Issue
  ): boolean {
    return Issue.within(start, end, this.innerValue)
  }

  /**
   * 获取代表该账期的内部计算数值
   * 
   * @returns {number} 内部计算数值
   * @memberof Issue
   */
  toNumber(): number {
    return this.innerValue
  }

  /**
   * 获取代表该账期的字符串
   * 
   * @returns {string} 账期字符串
   * @memberof Issue
   */
  toIssueStr(): string {
    return num2IssueStr(this.innerValue)
  }
}

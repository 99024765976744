import { ApiService, ApiServiceBase, ApiCallingFn as _ApiCallingFn } from './apiService'
import RestService from './restService'
import WsService from './wsService'
import UploadService from './uploadService'
import DownloadSevice from './downloadService'
import UserService from './userService'
import AccountService from './accountService'
import VoucherService from './voucherService'
import SubjectService from './subjectService'
import SourceService from './sourceService'
import ClientService from './clientService'
import PrintService from './printService'
import CacheService from './cacheService'
import AuthService from './authService'
import KeyService from './keyService'
import MetaService from './metaService'
import AiService from './aiService'
import GuideService from './guideService'
import VerificationPhone from './verificationPhone'
type ApiCallingFn<P = any, D = any> = _ApiCallingFn
export {
  ApiService,
  ApiServiceBase,
  ApiCallingFn,
  RestService,
  WsService,
  UploadService,
  DownloadSevice,
  AccountService,
  UserService,
  VoucherService,
  SubjectService,
  SourceService,
  MetaService,
  ClientService,
  PrintService,
  CacheService,
  AuthService,
  KeyService,
  AiService,
  GuideService,
  VerificationPhone
}

import { ApiService as service, ApiServiceBase, ApiCallingFn } from 'service/apiService'

interface ApiService extends ApiServiceBase {
    sendPhoneAuthcode: ApiCallingFn
    checkPhoneAuthcode: ApiCallingFn
    
}

const ApiService = service as ApiService
ApiService.define([
  { type: 'json', name: 'sendPhoneAuthcode', path: 'deal/code/send' },
  { type: 'json', name: 'checkPhoneAuthcode', path: 'deal/code/check' },
  
])

export default ApiService
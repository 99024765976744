import { ApiService as service, ApiServiceBase, ApiCallingFn } from './apiService'

interface ApiService extends ApiServiceBase {
  getMetadata: ApiCallingFn
  deleteMetadata: ApiCallingFn
  importMetadata: ApiCallingFn
  getMetadataTemplate: ApiCallingFn
}

const ApiService = service as ApiService
ApiService.define([
  { type: 'json', name: 'getMetadata', path: 'metadata/list' },
  { type: 'json', name: 'deleteMetadata', path: 'metadata/thoroughDelete' },
  { type: 'json', name: 'importMetadata', path: 'metadata/import' },
  { type: 'json', name: 'getMetadataTemplate', path: 'metadata/template' },
])

export default ApiService
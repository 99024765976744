import Day from 'dayjs'
import ApiService from './logService.api'
import UserService from './userService'
import AccountService from './accountService'

export const SKIP_REPORT = '　　'

const enum LogLevel {
  Warn = 'warn',
  Error = 'error',
  Fatal = 'fatal',
}

interface Log {
  userId: string
  accountId: string

  /**
   * 指示该错误是否已被捕获过
   *
   * true: 未被捕获过，由windows.onerror首次捕获，可能为一个严重的错误
   * false: 已被捕获过
   * @type {boolean}
   * @memberof Log
   */
  wild: boolean

  level: LogLevel
  timestamps: string
  message: string
  stack?: string
  source?: string
  line?: number
  col?: number
  error?: Error
}

const reportQueue: Log[] = []
const originError = window.console.error
const originWarn = window.console.warn
const getTimestamp = () => Day(Date.now()).format('YYYY-DD-MM HH:mm:ss:SSS')
// @ts-ignore
const getUserId = () => (UserService.current || {})._id || ''
// @ts-ignore
const getAccountId = () => (AccountService.current || {})._id || ''

if (ENV === 'product') {
  // 绑定全局错误处理事件
  window.onerror = (message, source, lineno, colno, error) => {
    report({
      userId: getUserId(),
      accountId: getAccountId(),
      wild: true,
      level: LogLevel.Fatal,
      timestamps: getTimestamp(),
      message,
      source,
      line: lineno,
      col: colno,
      error,
    })
  }

  // 拦截console.error
  window.console.error = hackedLog(LogLevel.Error)

  // 拦截console.warn
  // window.console.warn = hackedLog(LogLevel.Warn)
}

function hackedLog(level: LogLevel) {
  return (...args: any[]) => {
    const [skipFlag] = args.slice(-1)
    if (skipFlag === SKIP_REPORT) return

    report(parseReport(level, args))

    level === LogLevel.Warn
      ? originWarn.apply(window, args)
      : originError.apply(window, args)
  }
}

function parseReport(level: LogLevel, args: any[]): Log {
  let message = ''
  let stack = ''

  try {
    message = args.reduce((s, p) => {
      let m = ''
      if (p instanceof Error) {
        m = p.message
        stack = p.stack
      } else if (typeof p === 'string') {
        m = p
      } else {
        m = JSON.stringify(p)
      }

      return s + m + '\n'
    }, '')
  } catch (error) {
    message = ''
  }

  if (!stack) {
    const e = new Error()
    stack = e.stack
  }

  return {
    userId: getUserId(),
    accountId: getAccountId(),
    wild: false,
    level,
    timestamps: getTimestamp(),
    message,
    stack,
  }
}

function report(l: Log) {
  // NOTE
  // 如果将来考虑捕获来自wsService的接口报错，可能会导致：
  // - 若报告接口调用出错，则会陷入报告死循环
  // - 若wsService报错，则绝大多数情况下会报告两次（wsService本身，组件$notify）

  // NOTE
  // 由于短时间内可能会产生多条报错请求，
  // 而wsService对同一接口重复请求有限制，
  // 故使用缓冲队列强制多个请求顺序执行
  function queue() {
    ApiService.remoteLog(reportQueue[0])
      .then(() => {
        reportQueue.shift()

        if (reportQueue.length !== 0) return queue()
      })
      .catch(() => reportQueue.length = 0)
  }

  reportQueue.push(l)
  if (reportQueue.length === 1) {
    queue()
  }

  // NOTE
  // 别多问，就是条记录而已
  console.log(
    '只见少年项带银圈，手捏一柄钢叉，向一匹BUG尽力地刺去。\n' +
    '那BUG却将身一扭，反从他的胯下逃走了，在它的身后留下了一行足迹。\n' +
    '定睛一看，像极了一串报错文字：\n\n' +

    `×￥%+#*@/￥--#+￥   ${l.message.trim()}   @&×%#￥-*/*@#@\n\n` +

    '我那时并不知道这所谓BUG的是怎么一件东西，便是现在也不知道。\n' +
    '只是无端地觉得这畜生很伶俐，倒向你奔来，反从胯下窜了。它的代码是油一般的滑……\n\n' +
    '　　　　-- 节选自鲁迅传奇系列，《少年闰码》: Ln 159, Col 65',
  )
}

/**
 * 业务元数据
 *
 * @export
 * @interface Meta
 */
export interface Meta {
  /**
   * id
   *
   * @type {string}
   * @memberof Meta
   */
  _id?: string

  /**
   * 原始数据大类
   *
   * eg: 银行数据，销项数据，etc
   *
   * @memberof Meta
   */
  category?: string

  /**
   * 业务分类
   *
   * @type {string}
   * @memberof Meta
   */
  transactionCategory?: string

  /**
   * 业务详细列表
   *
   * @type {{ [key: string]: any }[]}
   * @memberof Meta
   */
  detail?: Array<{ [key: string]: any }>

  /**
   * ？？？
   *
   * @type {{ [key: string]: any }[]}
   * @memberof Meta
   */
  multi?: Array<{ [key: string]: any }>

  /**
   * 指示是否已删除
   *
   * @type {boolean}
   * @memberof Meta
   */
  deleted?: boolean

  /**
   * 指示是否为作废票
   *
   * @type {boolean}
   * @memberof Meta
   */
  invalid?: boolean

  /**
   * 指示是否为重复数据
   *
   * @type {boolean}
   * @memberof Meta
   */
  isRepeat?: boolean

  /**
   * 重复数据原因提示
   *
   * @type {string}
   * @memberof Meta
   */
  promptMsg?: string

  /**
   * 机器学习结果概率
   *
   * @type {number}
   * @memberof Meta
   */
  prob?: number

  /**
   * 机器学习修正字段集
   *
   * @type {{[key: string]: string}}
   * @memberof Meta
   */
  tips?: { [key: string]: string }

  /**
   * 业务字段
   */
  [key: string]: any
}

export function getMetaSearchKey(meta: Meta, keys: string[]): string {
  return keys.reduce((s, key) => s + meta[key], '')
}

/**
 * 用来排序
 *
 * @export
 * @enum {number}
 */
export const metaKey = {
  商品名称: 1,
  规格: 2,
  单位: 3,
  数量: 4,
  单价: 5,
  金额: 6,
  税率: 7,
  税额: 8,
  单据号: 9,
  申报项目: 10,
  商品编码版本号: 11
}

interface MetaCol {
  type: 'string' | 'number' | 'percentage' | 'dropdown'
  required: boolean
  options?: string[]
  [key: string]: any
}

export interface MetaFormula {
  direction: string
  subject: string
  value: string
  brief: string
  condition: string
}

export interface MetaHint {
  columns: string[]
  infos: { [col: string]: MetaCol }
  entries: MetaFormula[]
}

export const DEFAULT_META_银行数据 = {
  业务: '',
  交易时间: '',
  付款人: '',
  付款银行: '',
  付款账号: '',
  收款人: '',
  收款账号: '',
  金额: '',
  交易完余额: '',
  币种: '',
  汇率: '',
  备注: '',
}

export const DEFAULT_META_进项数据 = {
  业务: '',
  发票号码: '',
  发票代码: '',
  开票日期: '',
  销方税号: '',
  销货单位: '',
  商品摘要: '',
  金额: '',
  税率: '',
  税额: '',
  含税金额: '',
  发票类型: '',
  认证情况: '',
  备注: '',
}

export const DEFAULT_META_销项数据 = {
  业务: '',
  发票号码: '',
  发票代码: '',
  开票日期: '',
  购方税号: '',
  购货单位: '',
  商品摘要: '',
  金额: '',
  税率: '',
  税额: '',
  含税金额: '',
  发票类型: '',
  备注: '',
}

export const DEFAULT_META_费用数据 = {
  业务: '',
  发票号码: '',
  发票代码: '',
  开票日期: '',
  承担部门: '',
  报销人: '',
  商品摘要: '',
  金额: '',
  发票类型: '',
  备注: '',
}

export const DEFAULT_META_工资数据 = {
  姓名: '',
  身份证号码: '',
  是否雇员: '',
  手机号码: '',
  是否在职: '',
  入职日期: '',
  离职日期: '',
  收入额: '',
  养老: '',
  医疗: '',
  失业: '',
  个人公积金: '',
  个人所得税: '',
  个人商业保险: '',
  实发工资: '',
  单位社保: '',
  单位公积金: '',
  发放方式: '',
}

export const DEFAULT_META_其他数据 = {
  业务: '',
  详细信息: '',
  备注: '',
}

export const DEFAULT_METAHINT: MetaHint = {
  columns: [],
  infos: {},
  entries: [],
}

export const DEFAULT_METAHINT_银行数据: MetaHint = {
  columns: [
    '交易时间',
    '付款人',
    '付款银行',
    '付款账号',
    '收款人',
    '收款账号',
    '金额',
    '交易完余额',
    '币种',
    '汇率',
    '备注',
  ],
  infos: {
    交易时间: {
      type: 'string',
      required: false,
    },
    付款人: {
      type: 'string',
      required: false,
    },
    付款银行: {
      type: 'string',
      required: false,
    },
    付款账号: {
      type: 'string',
      required: false,
    },
    收款人: {
      type: 'string',
      required: false,
    },
    收款账号: {
      type: 'string',
      required: false,
    },
    金额: {
      type: 'number',
      required: false,
    },
    交易完余额: {
      type: 'number',
      required: false,
    },
    币种: {
      type: 'string',
      required: false,
    },
    汇率: {
      type: 'string',
      required: false,
    },
    备注: {
      type: 'string',
      required: false,
    },
  },
  entries: [],
}

export const DEFAULT_METAHINT_进项数据: MetaHint = {
  columns: [
    '发票号码',
    '发票代码',
    '开票日期',
    '销方税号',
    '销货单位',
    '商品摘要',
    '金额',
    '税率',
    '税额',
    '含税金额',
    '发票类型',
    '认证情况',
    '备注',
  ],
  infos: {
    发票号码: {
      type: 'string',
      required: false,
    },
    发票代码: {
      type: 'string',
      required: false,
    },
    开票日期: {
      type: 'string',
      required: false,
    },
    销方税号: {
      type: 'string',
      required: false,
    },
    销货单位: {
      type: 'string',
      required: false,
    },
    商品摘要: {
      type: 'string',
      required: false,
    },
    金额: {
      type: 'string',
      required: false,
    },
    税率: {
      type: 'string',
      required: false,
    },
    税额: {
      type: 'string',
      required: false,
    },
    含税金额: {
      type: 'string',
      required: false,
    },
    发票类型: {
      type: 'string',
      required: false,
    },
    认证情况: {
      type: 'string',
      required: false,
    },
    备注: {
      type: 'string',
      required: false,
    },
  },
  entries: [],
}

export const DEFAULT_METAHINT_销项数据: MetaHint = {
  columns: [
    '发票号码',
    '发票代码',
    '开票日期',
    '购货税号',
    '购货单位',
    '商品摘要',
    '金额',
    '税率',
    '税额',
    '含税金额',
  ],
  infos: {
    发票号码: {
      type: 'string',
      required: false,
    },
    发票代码: {
      type: 'string',
      required: false,
    },
    开票日期: {
      type: 'string',
      required: false,
    },
    购货税号: {
      type: 'string',
      required: false,
    },
    购货单位: {
      type: 'string',
      required: false,
    },
    商品摘要: {
      type: 'string',
      required: false,
    },
    金额: {
      type: 'number',
      required: false,
    },
    税率: {
      type: 'percentage',
      required: false,
    },
    税额: {
      type: 'number',
      required: false,
    },
    含税金额: {
      type: 'number',
      required: false,
    },
  },
  entries: [],
}

export const DEFAULT_METAHINT_费用数据: MetaHint = {
  columns: [
    '发票号码',
    '发票代码',
    '开票日期',
    '承担部门',
    '报销人',
    '商品摘要',
    '金额',
    '发票类型',
    '备注',
  ],
  infos: {
    发票号码: {
      type: 'string',
      required: false,
    },
    发票代码: {
      type: 'string',
      required: false,
    },
    开票日期: {
      type: 'string',
      required: false,
    },
    承担部门: {
      type: 'string',
      required: false,
    },
    报销人: {
      type: 'string',
      required: false,
    },
    商品摘要: {
      type: 'string',
      required: false,
    },
    金额: {
      type: 'number',
      required: false,
    },
    发票类型: {
      type: 'string',
      required: false,
    },
    备注: {
      type: 'string',
      required: false,
    },
  },
  entries: [],
}

export const DEFAULT_METAHINT_工资数据: MetaHint = {
  columns: [
    '姓名',
    '身份证号码',
    '是否雇员',
    '手机号码',
    '是否在职',
    '入职日期',
    '离职日期',
    '收入额',
    '养老',
    '医疗',
    '失业',
    '个人公积金',
    '个人所得税',
    '实发工资',
    '单位社保',
    '单位公积金',
    '发放方式',
  ],
  infos: {
    姓名: {
      type: 'string',
      required: false,
    },
    身份证号码: {
      type: 'string',
      required: false,
    },
    是否在职: {
      type: 'string',
      required: false,
    },
    入职日期: {
      type: 'string',
      required: false,
    },
    离职日期: {
      type: 'string',
      required: false,
    },
    是否雇员: {
      type: 'string',
      required: false,
    },
    手机号码: {
      type: 'string',
      required: false,
    },
    收入额: {
      type: 'number',
      required: false,
    },
    养老: {
      type: 'number',
      required: false,
    },
    医疗: {
      type: 'number',
      required: false,
    },
    失业: {
      type: 'number',
      required: false,
    },
    个人公积金: {
      type: 'number',
      required: false,
    },
    个人所得税: {
      type: 'number',
      required: false,
    },
    实发工资: {
      type: 'number',
      required: false,
    },
    单位社保: {
      type: 'number',
      required: false,
    },
    单位公积金: {
      type: 'number',
      required: false,
    },
    发放方式: {
      type: 'string',
      required: false,
    },
  },
  entries: [],
}

import Axios, { AxiosError } from 'axios';
import Config from 'config';

class HttpError extends Error {
  public payload: any;

  constructor (msg: string, payload: any) {
    super(msg);

    this.payload = payload;
  }
}

/**
 * XMLHttpRequests通信服务类
 *
 * @class RestService
 */
class RestService {
  private axios_v4 = Axios.create({
    baseURL: `${Config.v4.baseUrl}/`,
    timeout: 1000 * 30,
  });
  private axios_v5_mobile = Axios.create({
    baseURL: `${Config.v5_mobile.baseUrl}/`,
    timeout: 1000 * 30,
  });
  private axios_tag = Axios.create({
    baseURL: `${Config.tag.baseUrl}/`,
    timeout: 1000 * 30,
  });

  private axios_file = Axios.create({
    baseURL: `${Config.file.baseUrl}/`,
    timeout: 1000 * 30,
  });

  private axios_oss = Axios.create({
    baseURL: 'https://jfjun-resource.oss-cn-hangzhou.aliyuncs.com/',
    timeout: 1000 * 30,
  });

  public getJson<T>(uri: string): Promise<T> {
    return this.axios_oss.get<T>(uri).then((res) => res.data);
  }

  /**
   * 发送JSON类型的数据
   *
   * @param {string} uri 相对网络路径
   * @param {any} data FormData数据
   * @param {number} timeout 超时，以毫秒计
   * @returns {Promise<any>} 返回结果
   * @memberof RestService
   */
  public postJson(uri: string, data: any, timeout: number): Promise<any> {
    return this.post('json', uri, data, timeout);
  }

  /**
   * 发送"multipart/form-data"类型的数据
   *
   * @param {string} uri 相对网络路径
   * @param {FormData} data FormData数据
   * @param {number} timeout 超时，以毫秒计
   * @returns {Promise<any>} 返回结果
   * @memberof RestService
   */
  public postFile(uri: string, data: FormData, timeout: number): Promise<any> {
    return this.post('file', uri, data, timeout);
  }

  private async post(type: 'file' | 'json', uri: string, data: FormData, timeout: number): Promise<any> {
    try {
      // const axios = type === 'file' ? this.axios_file : this.axios_v4
      let axios;
      if (type === 'file') {
        axios = this.axios_file;
      }
      if (type === 'json') {
        if (['package/get', 'task/put', 'package/release', 'account/get'].includes(uri)) {
          axios = this.axios_tag;
        } else if (/^\/mobileApi/.test(uri)) {
          uri = uri.replace(/^\/mobileApi/, '');
          axios = this.axios_v5_mobile;
        } else {
          axios = this.axios_v4;
        }
      }
      const response = await axios.post(uri, data, {
        headers: {
          'Content-Type': type === 'file' ? 'multipart/form-data' : void 0,
        },
        timeout,
      });

      return response.data;
    } catch (error) {
      const e = error as AxiosError;

      if (e.response.status < 500) {
        throw new Error('网络异常，请稍后再试。');
      }

      if (typeof e.response.data === 'string') {
        throw new Error(e.response.data);
      }

      throw new HttpError('', e.response.data);
    }
  }
}

export default new RestService();

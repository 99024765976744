import io from 'socket.io-client';
import Config from 'config';
import { deepclone } from 'util/common';
import Debug from 'util/debug';
import Md5 from 'util/md5'
import CacheService from './cacheService';
import JwtService from './jwtService';
import { SKIP_REPORT } from './logService';
import { User } from 'model/user';

const TIMEOUT = 1000 * 60 * 2;
const RESPONSE_TIMEOUT = '请求超时，请刷新页面重试。若频繁发生此错误，请联系客服支持：400-682-0668';
const RESPONSE_MISSING_SERVICE = '网络繁忙，请稍后重试。若频繁发生此错误，请联系客服支持：400-682-0668';
const RESPONSE_DUPLICATE_REQUEST = '该请求正在处理中，请耐心等待。';

interface WsPackage {
  error: string;
  body?: any;
}

class WebSocketError extends Error {
  public payload: any;

  constructor (message: string, payload?: any) {
    super(message);
    this.payload = payload;
  }
}

class WsServise {
  private debug: Debug = new Debug('WebSocket');
  private ws: SocketIOClient.Socket;
  private queue: Set<string> = new Set();

  public async connect(username: string, password: string): Promise<any> {
    const [mc, jwt, user] = await this.authorize(username, password);

    await this.link(mc, jwt);
    CacheService.open(user._id);
    await JwtService.saveJwt(jwt);
    return user;
  }

  public async connectByVerification(phone: string, password: string, verification: string): Promise<any> {
    const [mc, jwt, user] = await this.authorizeByVerification(phone, password, verification);

    await this.link(mc, jwt);
    CacheService.open(user._id);
    await JwtService.saveJwt(jwt);
    return user;
  }

  /**微信用户登录 */
  public async connectByWx(code: string): Promise<any> {
    const d = await this.authorize_wx(code);

    if (d.jwt) {
      await this.link(d.mc, d.jwt);
      CacheService.open(d.user._id);
      await JwtService.saveJwt(d.jwt);
      return d.user;
    } else {
      return d;
    }
  }
  /**钉钉用户登录 */
  public async connectByDD({ corpId, code }): Promise<any> {
    const d = await this.authorize_DD({ corpId, code });

    if (d.jwt) {
      await this.link(d.mc, d.jwt);
      CacheService.open(d.user._id);
      await JwtService.saveJwt(d.jwt);
      return d.user;
    } else {
      return d;
    }
  }

  private async authorize_DD({ corpId, code }): Promise<any> {
    const wsAuth = io(`${Config.websocket.auth}`);

    let jwt: string;
    if (code === void 0) {
      jwt = await JwtService.loadJwt();
    }

    const ret = await this.sendInternal(wsAuth, 'auth', 'ding/login', {
      code,
      corpId,
      jwt,
    });

    wsAuth.disconnect();
    wsAuth.close();

    return ret;
  }
  private async authorize_wx(code?: string): Promise<any> {
    const wsAuth = io(`${Config.websocket.auth}`);

    let jwt: string;
    if (code === void 0) {
      jwt = await JwtService.loadJwt();
    }

    const ret = await this.sendInternal(wsAuth, 'auth', 'wx/login', {
      code,
      jwt,
    });

    wsAuth.disconnect();
    wsAuth.close();

    return ret;
  }

  /**获取注册验证码 */
  public async sendAuthcode(info: any): Promise<any> {
    const wsAuth = io(`${Config.websocket.auth}`);

    const retData = await this.sendInternal(wsAuth, 'auth', 'send/verification', info);

    wsAuth.disconnect();

    return retData;
  }

  /**手机绑定微信号进行注册*/
  public async registerByWx(info: any): Promise<any> {
    const wsAuth = io(`${Config.websocket.auth}`);

    const d = await this.sendInternal(wsAuth, 'auth', 'wx/register', info);

    await this.link(d.mc, d.jwt);
    CacheService.open(d.user._id);
    await JwtService.saveJwt(d.jwt);
    return d.user;
  }

  /**手机绑定钉钉号进行注册*/
  public async registerByDD(info: any): Promise<any> {
    const wsAuth = io(`${Config.websocket.auth}`);

    const d = await this.sendInternal(wsAuth, 'auth', 'ding/register', info);

    await this.link(d.mc, d.jwt);
    CacheService.open(d.user._id);
    await JwtService.saveJwt(d.jwt);
    return d.user;
  }

  public async refresh(session: string) {
    CacheService.open(session);

    const [mc, jwt, user] = await this.authorize();
    await this.link(mc, jwt);
    await JwtService.saveJwt(jwt);

    return user;
  }

  public disconnect() {
    this.ws.disconnect();
    JwtService.clearJwt();
    CacheService.clear();
    CacheService.close();
  }

  public send<T = any>(api: string, payload: any = {}): Promise<T> {
    return this.sendInternal<T>(this.ws, 'msg', api, payload);
  }

  private async authorize(username?: string, password?: string): Promise<[string, string, any]> {
    const wsAuth = io(`${Config.websocket.auth}`);

    let jwt: string;
    if (username === void 0) {
      jwt = await JwtService.loadJwt();
    }

    let isSKUser = false;

    if (username) {
      isSKUser = username.trim() === 'jiacheng.xiang@jfjun.com';
      if (isSKUser) {
        //isSKUser 为1 时，采用税控用户登录
        localStorage.setItem('isSKUser', '1');
      } else {
        localStorage.setItem('isSKUser', '0');
      }
    } else {
      isSKUser = localStorage.getItem('isSKUser') === '1';
    }
    const { jwt: freshJwt, mc, user } = await this.sendInternal(
      wsAuth,
      'auth',
      'user/login',
      isSKUser
        ? {
          username,
          password,
          jwt,
          type: 'device',
        }
        : {
          username,
          password,
          jwt,
        }
    );

    wsAuth.disconnect();

    return [mc, freshJwt, user];
  }

  private async authorizeByVerification(
    phone?: string,
    password?: string,
    verification?: string
  ): Promise<[string, string, any]> {
    const wsAuth = io(`${Config.websocket.auth}`);

    const { jwt: freshJwt, mc, user } = await this.sendInternal(wsAuth, 'auth', 'verification/login', {
      phone,
      password,
      verification,
    });

    wsAuth.disconnect();

    return [mc, freshJwt, user];
  }

  private async link(mc: string, jwt: string): Promise<any> {
    mc = mc.replace(/(http|https):\/\//, '');

    const { protocol } = window.document.location;
    if (protocol.includes('https')) {
      this.ws = io(`wss://${mc}`);
    } else {
      this.ws = io(`ws://${mc}`);
    }

    const mcLogin = (token: string): Promise<any> => {
      let isSKUser = localStorage.getItem('isSKUser') === '1';
      return this.sendInternal(
        this.ws,
        'user',
        'user/login',
        isSKUser
          ? {
            jwt: token,
            type: 'device',
          }
          : {
            jwt: token,
          }
      );
    };

    this.ws.on('reconnect', () => {
      mcLogin(jwt);
    });

    return mcLogin(jwt);
  }

  private async sendInternal<T = any>(
    ws: SocketIOClient.Socket,
    event: string,
    api: string,
    payload: any,
    timeout: number = TIMEOUT
  ): Promise<T> {
    const colorCode = this.debug.colorCode();
    const timestamp = Date.now();
    let isSuccess = true;
    let logPayload;
    const md5Payload = Md5(payload)
    try {
      this.logTraffic('log', 'out', colorCode, api, payload);

      if (this.queue.has(`${api}-${md5Payload}`)) {
        throw RESPONSE_DUPLICATE_REQUEST;
      } else {
        this.queue.add(`${api}-${md5Payload}`);

        return (logPayload = await Promise.race([
          this.timeoutCounter(timeout),
          this.sendHandler(ws, event, api, payload),
        ]));
      }
    } catch (error) {
      (isSuccess = false), (logPayload = error);

      if (typeof error === 'string') {
        throw new WebSocketError(error);
      } else if (error.error !== void 0) {
        throw new WebSocketError(error.error, error.body);
      } else {
        throw error;
      }
    } finally {
      const interval = Date.now() - timestamp;

      this.logTraffic(
        isSuccess ? 'log' : 'error',
        'in',
        colorCode,
        api,
        logPayload,
        interval
      );
      this.queue.delete(`${api}-${md5Payload}`);
    }
  }

  private timeoutCounter(timeout: number) {
    return new Promise((resolve, reject) => setTimeout(() => reject(RESPONSE_TIMEOUT), timeout));
  }

  private sendHandler(ws: SocketIOClient.Socket, event: string, api: string, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const packet = { path: `/${api}`, body: payload };

      ws.emit(event, packet, (ack: WsPackage) =>
        ack.error === void 0
          ? resolve(ack.body)
          : reject(/未找到服务节点/.test(ack.error) ? RESPONSE_MISSING_SERVICE : ack)
      );
    });
  }

  private logTraffic(
    type: 'log' | 'warn' | 'error',
    direction: 'in' | 'out',
    colorCode: string,
    api: string,
    payload: any,
    timestamp: number = 0
  ) {
    const flatQueue = `[${Array.from(this.queue).reduce((s, query) => `${s} "${query}",`, '')} ]`;
    const legend = '  ';
    const prefix = direction === 'in' ? '<=' : '=>';
    const interval = timestamp !== 0 ? `- ${timestamp}ms` : '';
    const index = `background-color: ${colorCode}`;
    const font = 'font-weight: bold';

    this.debug[type](
      `${flatQueue}\r\n%c${legend}%c ${prefix} %c${api} ${interval}\r\n`,
      index,
      '',
      font,
      payload,
      SKIP_REPORT
    );
  }
}

export default new WsServise();

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import './feather.scss'

import FeatherX from './assets/x'
import FeatherFilter from './assets/filter'
import FeatherSort from './assets/arrow-down'
import FeatherChevrons from './assets/chevrons'
import FeatherInfo from './assets/info'
import FeatherDone from './assets/check-circle'
import FeatherWarn from './assets/alert-circle'
import FeatherError from './assets/x-circle'
import FeatherAdd from './assets/plus-circle'
import FeatherDelete from './assets/x-circle'
import FeatherCalendar from './assets/calendar'
import FeatherSearch from './assets/search'
import FeatherEye from './assets/eye'
import FeatherEyeOff from './assets/eye-off'
import FeatherKefu from './assets/kefu'

@Component
export default class Feather extends Vue {
  @Prop()
  public icon: string
  @Prop()
  public class: string
  @Prop({ default: '16px' })
  public width: string

  @Prop({ default: '16px' })
  public height: string

  private calStyle() {
    return {
      height: this.height,
      width: this.width
    }
  }

  private renderIcon(h) {
    if (this.icon === 'x') return FeatherX(h)
    if (this.icon === 'filter') return FeatherFilter(h)
    if (this.icon === 'sort') return FeatherSort(h)
    if (this.icon === 'chevrons') return FeatherChevrons(h)
    if (this.icon === 'info') return FeatherInfo(h)
    if (this.icon === 'done') return FeatherDone(h)
    if (this.icon === 'warn') return FeatherWarn(h)
    if (this.icon === 'error') return FeatherError(h)
    if (this.icon === 'add') return FeatherAdd(h)
    if (this.icon === 'delete') return FeatherDelete(h)
    if (this.icon === 'calendar') return FeatherCalendar(h)
    if (this.icon === 'search') return FeatherSearch(h)
    if (this.icon === 'eye') return FeatherEye(h)
    if (this.icon === 'eye-off') return FeatherEyeOff(h)
    if (this.icon === 'kefu') return FeatherKefu(h)
    return ''
  }

  public render(h) {
    return (
      <div class="jfj-icon" style={this.calStyle()}>
        {this.renderIcon(h)}
      </div>
    )
  }
}

import WsService from 'service/wsService'
import RestService from 'service/restService'

/**
 * api定义
 * 
 * @export
 * @interface ApiDefinition
 */
export interface ApiDefinition {
  // api名称，作为apiService调用方法名
  name: string

  // api路径
  path: string

  // 传输数据类型
  type: 'json' | 'http' | 'file'
}

/**
 * api调用方法定义
 * 
 * @export
 * @interface ApiCallingFn
 * @param {P} payload 需要发送的参数
 * @param {D} timeout 超时时长，以毫秒为单位
 * @template P 发送参数类型
 * @template D 返回数据类型
 */
export interface ApiCallingFn<P = any, D = any> {
  (payload?: P, timeout?: number): Promise<D>
}

/**
 * api定义服务
 * 
 * 通信接口调用中间层
 * 
 * @export
 * @class ApiServiceBase
 */
export class ApiServiceBase {
  private queue: Set<string> = new Set()

  /**
   * 定义api调用接口
   * 
   * @param {ApiDefinition[]} newApis 包含定义的数组
   * @memberof ApiServiceBase
   */
  define(newApis: ApiDefinition[]) {
    newApis.forEach(api => {
      if (this.hasOwnProperty(api.name)) {
        return console.warn((`[api]Definition "${api.name}" duplicated. Abort api generation.`))
      }

      Object.defineProperty(this, api.name, { get: () => this.request(api) })
    })
  }

  /**
   * 生成公开的api调用方法
   * 
   * @private
   * @param {ApiDefinition} api api定义对象
   * @returns {ApiCallingFn} api调用方法
   * @memberof ApiServiceBase
   */
  private request(api: ApiDefinition): ApiCallingFn {
    return async (payload?: any, timeout?: number) => {
      if (this.queue.has(api.name)) {
        throw new Error("该请求正在处理中，请耐心等待。")
      } else {
        this.queue.add(api.name)
      }

      try {
        return api.type === 'json'
          ? WsService.send(api.path, payload)
          : api.type === 'http'
            ? RestService.postJson(api.path, payload, timeout)
            : RestService.postFile(api.path, payload, timeout)
      } catch (error) {
        throw error
      } finally {
        this.queue.delete(api.name)
      }
    }
  }
}

export const ApiService = new ApiServiceBase()
import Vue from "vue";
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Notice } from './notice'
import Feather from '../icons/feather'
import Row from '../grid/row'
import Button from '../button/button'
import Text from '../text/text'
import { Workflow, WorkflowParam } from '../workflow.util'
import './notify.scss'

@Component
class Notify extends Vue {
  @Prop()
  data: Notice

  @Prop()
  onclosed: (notice: Notice) => void

  get hasOprations() {
    return this.data.operations.length !== 0
  }

  get calStyle() {
    return {
      'jfj-notify': true,
      [`jfj-notify--${this.data.type}`]: true,
      'jfj-notify--deleted': this.data.isDeleted
    }
  }

  close() {
    this.data.isHover = false
    this.onclosed(this.data)
  }

  mounted() {
    if (!(this.$el as HTMLElement).style.height) {
      setTimeout(() => {
        (this.$el as HTMLElement).style.height = `${this.$el.clientHeight}px`
      }, 500)
    }
  }

  render(h) {
    return (
      <div class={this.calStyle} id={this.data.key}>
        <header>
          <Text type="head" bold>{this.data.title}</Text>
          {this.data.showClose &&
            <Feather
              icon="x"
              class="close"
              nativeOnClick={this.close}></Feather>
          }
        </header>

        <section>
          {this.data.msg === ''
            ? (this.data.render && this.data.render(h))
            : <Text padding="0" whiteSpace>{this.data.msg}</Text>}
        </section>

        {this.hasOprations &&
          <footer>
            <Row>
              {this.data.operations.map(op =>
                <Button ghost onClick={e => op.onclick()}>{op.title}</Button>
              )}
            </Row>
          </footer>
        }
      </div>
    )
  }
}

@Component
export default class NotifyGroup extends Vue {
  private notices: Notice[] = []
  private cancelTokens: Map<string, number> = new Map()

  constructor () {
    super()

    Workflow.define<Notice>('notice.add', this.add)
    Workflow.define<Notice>('notice.remove', ({ data }) => {
      this.remove(data)
    })
  }

  add({ data: notice, resolve }: WorkflowParam<Notice>) {
    setTimeout(function () {
      notice.isDeleted = false
    })

    const cancelToken = notice.autoClose && window.setTimeout(() => {
      this.remove(notice)
    }, notice.expired);
    this.cancelTokens.set(notice.key, cancelToken)

    this.notices.push(notice)
  }

  remove(notice: Notice) {
    if (!notice.isHover) {
      const token = this.removeToken(notice.key)
      window.clearTimeout(token)

      setTimeout(() => {
        const index = this.notices.indexOf(notice)
        index !== -1 && this.notices.splice(index, 1)
      }, 500)

      notice.isDeleted = true
      notice.close()

    } else {

      setTimeout(() => {
        this.remove(notice)
      }, 1000);

    }

  }

  private removeToken(key: string): number {
    let token = this.cancelTokens.get(key)
    if (token !== void 0) {
      this.cancelTokens.delete(key)
    }
    return token
  }

  render(h) {
    return (
      <div class="jfj-notify-group">
        {this.notices.map(notice =>
          <Notify key={notice.key} data={notice} onclosed={this.remove}
            nativeOnMouseenter={(e) => notice.isHover = true}
            nativeOnMouseleave={(e) => notice.isHover = false}
          ></Notify>
        )}
      </div>
    )
  }
}
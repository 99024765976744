/**
 * 文件下载服务
 *
 * 用于规避浏览器弹出窗口拦截问题（大概）
 *
 * @class DownloadService
 */
class DownloadService {
  private wrapper: HTMLElement

  constructor () {
    this.wrapper = window.document.createElement('div')
    this.wrapper.style.display = 'none'

    window.document.body.appendChild(this.wrapper)
  }

  /**
   * 下载文件
   *
   * @param {string} url 文件链接
   * @memberof DownloadService
   */
  public download(url: string) {
    const a = window.document.createElement('a')
    // const filename = link.slice(link.lastIndexOf('/') + 1)

    // a.setAttribute('target', '_blank')
    a.setAttribute('href', url)
    // a.setAttribute('download', filename)
    a.setAttribute('download', '')

    this.wrapper.appendChild(a)
    a.click()
    this.wrapper.removeChild(a)
  }
}

export default new DownloadService()

import { ApiService as service, ApiServiceBase, ApiCallingFn } from './apiService'

interface ApiService extends ApiServiceBase {
  remoteLog: ApiCallingFn
}

const ApiService = service as ApiService
ApiService.define([
  { type: 'json', name: 'remoteLog', path: 'log/save' },
])

export default ApiService

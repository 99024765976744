import makeReactive from 'util/makeReactive'
import ApiService from './clientService.api'
import UserService from './userService'
import { Client } from 'model/client'

class ClientService {
  public clients: Client[] = []

  public async fetchClients() {
    this.clients = this.parseClient(await ApiService.fetchClients({
      type: 'customer'
    }))
  }

  public async createClient(client: Client) {
    client.parent = UserService.current._id
    client.createdAt = new Date().toISOString().slice(0, 10)

    await ApiService.createClient(client)
    return this.fetchClients()
  }

  public async deleteClient(client: Client) {
    await ApiService.deleteClient({
      userId: client._id
    })
    return this.fetchClients()
  }

  public async updateClient(client: Client) {
    await ApiService.updateClient(client)
    return this.fetchClients()
  }

  private parseClient(raws: any[]): Client[] {
    return raws.map(raw => Object.assign(raw, { password: '' })) as Client[]
  }
}

export default makeReactive(new ClientService())

import { assign } from 'util/common'
import { Entry } from './entry'
import { Meta, getMetaSearchKey } from './meta'

/**
 * 票据
 *
 * @export
 * @class Resource
 */
export class Resource {
  /**
   * id
   *
   * @type {string}
   * @memberof Resource
   */
  public _id: string = ''


  /**
   * @description 
   * @type {string}
   * @memberof Resource
   */
  public source: string = ''
  /**
   * 凭证id
   *
   * @type {string}
   * @memberof Resource
   */
  public voucherId: string = ''

  /**
   * 凭证类型
   *
   * @type {string}
   * @memberof Resource
   */
  public voucherType: string = ''

  /**
   * @description 凭证编号
   * @type {number}
   * @memberof Resource
   */
  public voucherNumber: number = 0

  /**
   * 业务类型
   *
   * @type {string}
   * @memberof Resource
   */
  public type: string = ''

  /**
   * 数据类型
   *
   * @type {string}
   * @memberof Resource
   */
  public category: string = ''

  /**
   * 图片链接
   *
   * @type {string}
   * @memberof Resource
   */
  public src: string = ''

  /**
   * 指示是否已删除
   *
   * @type {boolean}
   * @memberof Resource
   */
  public deleted: boolean = false

  /**
   * 指示是否已作废
   *
   * @type {boolean}
   * @memberof Resource
   */
  public invalid: boolean = false

  /**
   * 指示是否为重复数据
   *
   * @type {boolean}
   * @memberof Resource
   */
  public duplicated: boolean = false

  /**
   * 分录
   *
   * @type {Entry[]}
   * @memberof Resource
   */
  public entries: Entry[] = []

  /**
   * 业务数据
   *
   * @type {Meta}
   * @memberof Resource
   */
  public metadata: Meta = {}

  /**
   * 业务详细
   *
   * @type {{ [key: string]: any }[]}
   * @memberof Resource
   */
  public detail: Array<{ [key: string]: any }> = []

  constructor (raw: any = {}, voucherId: string = '', voucherType: string = '', voucherNumber: number = 0) {
    assign(this, raw)

    this.voucherId = voucherId
    this.voucherType = voucherType
    this.voucherNumber = voucherNumber
    this.entries = (raw.entries || []).map((entry) => new Entry(entry))
    this.detail = raw.detail || this.metadata.detail || []

    // HACK: 仅限v4 -> v5迁移数据，防止src丢失
    if (this.src === '') {
      this.src = this.metadata.src
    }
  }

  public get hasImage(): boolean {
    return !!this.src
  }
  /**
 * 获取凭证编号
 *
 * @returns {string}
 * @memberof Voucher
 */
  public get index(): string {
    return `记-${this.voucherNumber.toString().padStart(3, '0')}`
  }

  public get hasDetails(): boolean {
    return this.detail && this.detail.length !== 0
  }

  public get hasMetadata(): boolean {
    return Object.keys(this.metadata).length !== 0
  }

  public getMetaSerachKey(keys: string[]): string {
    return getMetaSearchKey(this.metadata, keys)
  }
}

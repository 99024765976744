import AccountService from './accountService'
import ApiService from './sourceService.api'

class SourceService {
  fetchMetaData(type: string): Promise<any[]> {
    return ApiService.getMetadata({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      type,
    })
  }

  deleteMetaData(type: string, _id: string): Promise<any[]> {
    return ApiService.deleteMetadata({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      type,
       metadataIds: [_id]
    })
  }

  import(url: string, type: string, format: string = 'excel'): Promise<any[]> {
    return ApiService.importMetadata({
      accountId: AccountService.current._id,
      issue: AccountService.current.selectedIssue,
      type,
      url,
      format
    })
  }

  async getTemplate(type: string): Promise<string> {
    const { url } = await ApiService.getMetadataTemplate({
      accountId: AccountService.current._id,
      type,
    })
    return url
  }
}

export default new SourceService()
/**
 * 工作流事件参数对象定义
 * 
 * @template T 接受的数据类型
 */
export type WorkflowParam<T = any> = {
  data?: T,
  resolve?: (value?: any | PromiseLike<any>) => void,
  reject?: (value?: any | PromiseLike<any>) => void
}

/**
 * 工作流事件处理方法定义
 * 
 * @template T 接受的数据类型
 */
type WorkflowHandler<T = any> = (option?: WorkflowParam<T>) => void

/**
 * 工作流
 * 
 * 一种异步事件分发机制
 * 
 * @class Workflow
 */
class _Workflow {
  private flows: Map<string, WorkflowHandler> = new Map()

  /**
   * 定义一个异步工作流
   * 
   * @template T 接受的数据类型
   * @template any 默认数据类型
   * @param {string} name 工作流名称
   * @param {WorkflowHandler<T>} flow 工作流事件处理方法
   * @memberof Workflow
   */
  define<T = any>(name: string, flow: WorkflowHandler<T>): void {
    if (this.flows.has(name)) {
      console.warn(`Workflow "${name}" already exists. Override it.`)
    }

    this.flows.set(name, flow)
  }

  /**
   * 开始一个异步工作流
   * 
   * @template T 传递给接收方的数据类型
   * @template any 传递数据的默认类型
   * @template U 返回的数据类型
   * @template any 返回数据的默认类型
   * @param {string} name 工作流名称
   * @param {T} [data] 传递给接收方的数据对象
   * @param {boolean} [autoSkip=false] 如果指定工作流不存在，是否自动返回
   * @returns {Promise<U>} 包含有返回数据的Promise对象
   * @memberof _Workflow
   */
  start<T = any, U = any>(name: string, data?: T, autoSkip: boolean = false): Promise<U> {
    let flow = this.flows.get(name)

    if (flow === void 0) {
      if (autoSkip) {
        return Promise.resolve(void 0)
      } else {
        throw new Error(`Workflow "${name}" does not exist.`)
      }
    }

    return new Promise((resolve, reject) => {
      flow({ data, resolve, reject })
    })
  }
}

export const Workflow = new _Workflow()

import { User } from 'model/user'
import Issue from 'model/issue'
import { ActionDefinition } from 'model/auth'
import moment, { Moment } from 'moment'
import { WxInfo } from '../model/user'

export const enum AccountTaxPeriod {
  月报 = '月报',
  季报 = '季报',
}

export const enum AccountTaxType {
  一般纳税人 = '一般纳税人',
  小规模纳税人 = '小规模纳税人',
  个人独资企业或有限合伙 = '个人独资企业或有限合伙',
}

export const enum Rules {
  小企业会计准则 = '2013小企业会计准则',
  企业会计准则 = '企业会计准则',
}

/**
 * 税务信息
 *
 * @interface AccountTaxInfo
 */
export interface AccountTaxInfo {
  /**
   * 税号
   *
   * @type {string}
   * @memberof AccountTaxInfo
   */
  taxNumber: string

  /**
   * 税管所
   *
   * @type {string}
   * @memberof AccountTaxInfo
   */
  taxBureau: string

  /**
   * 专员号
   *
   * @type {string}
   * @memberof AccountTaxInfo
   */
  taxAdmin: string

  /**
   * 联系方式
   *
   * @type {string}
   * @memberof AccountTaxInfo
   */
  taxAdminPhone: string

  /**
   * 证书到期日
   *
   * @type {string}
   * @memberof AccountTaxInfo
   */
  certificateExpired: string

  /**
   * 有无税控机
   *
   * @type {boolean}
   * @memberof AccountTaxInfo
   */
  taxPrinter: boolean

  /**
   * 所得税申报
   *
   * @type {('国税' | '地税')}
   * @memberof AccountTaxInfo
   */
  incomeTaxReturn: '国税' | '地税'

  /**
   * 所得税类型
   *
   * @type {('查账征收' | '核定征收')}
   * @memberof AccountTaxInfo
   */
  incomeTaxType: '查账征收' | '核定征收'

  /**
   * 核定征收率
   *
   * @type {number}
   * @memberof AccountTaxInfo
   */
  incomeTaxRate: number
}

/**
 * 账套银行信息
 *
 * @interface AccountBankInfo
 */
export interface AccountBankInfo {
  /**
   * 账户类型
   *
   * @type {string}
   * @memberof AccountBankInfo
   */
  accountType: string

  /**
   * 银行账号
   *
   * @type {string}
   * @memberof AccountBankInfo
   */
  bankAccount: string

  /**
   * 开户银行
   *
   * @type {string}
   * @memberof AccountBankInfo
   */
  bankName: string
}

/**
 * 会计准则
 *
 * @interface AccountRule
 */
export interface AccountRule {
  '2013小企业会计准则': any
  '企业会计准则': any
  '民间非营利组织会计制度': any
}

/**
 * 账套数据对象
 *
 * @export
 * @class Account
 */
export class Account {
  /**
   * id
   *
   * @type {string}
   * @memberof Account
   */
  public _id: string = ''

  /**
   * 代账公司的ID
   *
   * @type {string}
   * @memberof Account
   */
  public acId: string = ''

  /**
   * 组id
   *
   * @type {string}
   * @memberof Account
   */
  public group: string = ''

  /**
   * 做账状态
   *
   * @type {string}
   * @memberof Account
   */
  public accountStat: string = ''

  public client2: string = ''
  public isPrivate: string = ''
  public level: string = ''

  /**
   * 所属客户
   *
   * @type {string}
   * @memberof Account
   */
  public ac: string = ''

  /**
   * 所属顾问
   *
   * @type string
   * @memberof Account
   */
  public acAccountant: string = ''
  public acAccountants: string[] = []
  public acAccountantId: string = ''

  /**
   * 所属专家
   *
   * @type string
   * @memberof Account
   */
  public fscAccountant: string = ''
  public fscAccountants: string[] = []
  public fscAccountantId: string = ''

  /**
   * 是否有评论更新
   *
   * @type {boolean}
   * @memberof Account
   */
  public remark: { [userId: string]: boolean } = { '': true }

  /**
   * 报告是否异常
   *
   * @type {boolean}
   * @memberof Account
   */
  public report: boolean = false

  /**
   * 资产负债表查看模式
   *
   * @type {"default"}
   * @memberof Account
   */
  public assetModel: 'default' | 'assign' = 'default'

  /**
   * 当前账期
   *
   * @type {string}
   * @memberof Account
   */
  public currentIssue: string = ''

  /**
   * 历史账期
   *
   * @type {string}
   * @memberof Account
   */
  public historyIssue: string = ''

  /**
   * 当前账期报表是否平衡/勾稽关系是否正确
   *
   * @type {boolean}
   * @memberof Account
   */
  public equilibrium: boolean = false

  /**
   * 初始账期
   *
   * @type {string}
   * @memberof Account
   */
  public initIssue: string = moment().format('YYYY-MM')

  /**
   * 是否启用库存模块
   *
   * @type {boolean}
   * @memberof Account
   */
  public isUseStock: boolean = false

  /**
   * 名称
   *
   * @type {string}
   * @memberof Account
   */
  public name: string = ''

  /**
   * 简称
   *
   * @type {string}
   * @memberof Account
   */
  public abbreviation: string = ''

  /**
   * 企业联系人
   *
   * @type {string}
   * @memberof Account
   */
  public contact: string = ''

  /**
   * 企业联系人手机号码
   *
   * @type {string}
   * @memberof Account
   */
  public phone: string = ''

  /**
   * 名称拼音
   *
   * @type {string}
   * @memberof Account
   */
  public pinyin: string = ''

  /**
   * 简拼
   *
   * @type {string}
   * @memberof Account
   */
  public jianpin: string = ''

  /**
   * 当前查看的账期
   *
   * @type {string}
   * @memberof Account
   */
  public selectedIssue: string = ''

  /**
   * 报税周期
   *
   * @type {AccountTaxPeriod}
   * @memberof Account
   */
  public taxPeriod: AccountTaxPeriod = AccountTaxPeriod.季报

  /**
   * 纳税类型
   *
   * @type {AccountTaxType}
   * @memberof Account
   */
  public taxpayerType: AccountTaxType = AccountTaxType.小规模纳税人

  /**
   * 纳税申报初始账期
   *
   * @type {AccountTaxType}
   * @memberof Account
   */
  public taxDeclarationIssue: string

  /**
   * 是否为工业特殊行业
   *
   * @type {boolean}
   * @memberof Account
   */
  public isSpecialIndustry: boolean = false

  /**
   * 税务信息
   *
   * @type {AccountTaxInfo}
   * @memberof Account
   */
  public taxInfo: AccountTaxInfo = {
    taxNumber: '',
    taxBureau: '',
    taxAdmin: '',
    taxAdminPhone: '',
    certificateExpired: '',
    taxPrinter: true,
    incomeTaxReturn: '地税',
    incomeTaxType: '查账征收',
    incomeTaxRate: 0,
  }

  /**
   * 所属行业
   *
   * @type {string}
   * @memberof Account
   */
  public industry: string = ''

  /**
   * 注册地址
   *
   * @type {string}
   * @memberof Account
   */
  public address: string = ''

  /**
   * 经营范围
   *
   * @type {string}
   * @memberof Account
   */
  public businessScope: string = ''

  /**
   * 银行信息
   *
   * @type {AccountBankInfo[]}
   * @memberof Account
   */
  public banks: AccountBankInfo[] = []

  /**
   * 附件
   *
   * @type {any[]}
   * @memberof Account
   */
  public attachments: any[] = []

  /**
   * @description 账套创建时间
   * @type {string}
   * @memberof Account
   */
  public createdAt: string = ''

  /**
   * 账套级别的操作黑名单
   *
   * @type {ActionDefinition[]}
   * @memberof Account
   */
  public deny: ActionDefinition[] = []

  /**
   * [obsolete]
   * 客户类型
   *
   * 临时性方案。用于标记共享中心或管理帐体系
   *
   * @type {string}
   * @memberof Account
   */
  public clientType: string = '基础财税'

  /**
   * 本年累计是否靠后
   *
   * @type {boolean}
   * @memberof Account
   */
  public sheetYearAfter: boolean = false

  /**
   * 会计准则
   *
   * @type {string}
   * @memberof Account
   */
  public rule: keyof AccountRule = '2013小企业会计准则'

  /**
   * @description 是否是新会计准则
   * @type {boolean}
   * @memberof Account
   */
  public is_new_rule: boolean = false

  /**
   * v4迁移标记位
   *
   * 值为最末迁移期的后一期
   * eg: 迁移最后一期为2077-01，v4标记位值为2077-02
   *
   * @type {string}
   * @memberof Account
   */
  public v4?: string = '1970-01'

  /**
   * 微信授权码
   *
   * @type {string}
   * @memberof Account
   */
  public authKey: string = ''


  /**
   * 账套设置处的微信|钉钉用户绑定信息
   * 
   */
  public share: WxInfo[] = []

  constructor (raw: any) {
    Object.assign(this, raw)

    this.convertPinyin()
    this.convertJianpin()
    this.calInitSelectIssue()
  }

  /**
   * 当前账期的状态
   *
   * @readonly
   * @type {string}
   * @memberof Account
   */
  public get issueState(): string {
    return this.selectedIssue === this.currentIssue ? '做账中' : '已结账'
  }

  /**
   * 判断当前所选期号是否属于v4迁移期间
   *
   * @readonly
   * @type {boolean}
   * @memberof Account
   */
  public get isMigratedIssue(): boolean {
    return Issue.diff(this.v4, this.selectedIssue) > 0
  }

  public get isRuleMinfei(): boolean {
    return this.rule === '民间非营利组织会计制度'
  }

  private convertPinyin() {
    this.pinyin = (this.pinyin || '').toLowerCase()
  }

  private convertJianpin() {
    this.jianpin = Array.from(this.pinyin)
      .map((char) => char.charCodeAt(0) >= 65 || char.charCodeAt(0) <= 90, '')
      .join('')
      .toLowerCase()
  }

  private calInitSelectIssue() {
    this.selectedIssue = this.currentIssue

    // TODO

    // const currentIssue = this.currentIssue
    // if (this.issueState === '做账中') {
    //   return (this.selectedIssue = currentIssue)
    // }

    // const systemIssue = Issue.systemIssue
    // if (Issue.diff(currentIssue, systemIssue) <= 0) {
    //   return (this.selectedIssue = currentIssue)
    // }

    // const initIssue = this.initIssue
    // if (Issue.diff(initIssue, systemIssue) > 0) {
    //   return (this.selectedIssue = initIssue)
    // }

    // const last = Issue.last(this.currentIssue).toIssueStr()
    // if (this.issueState === '未开始') {
    //   return (this.selectedIssue = last)
    // } else {
    //   return (this.selectedIssue = currentIssue)
    // }
  }
}

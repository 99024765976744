import { assign } from 'util/common'
import { ActionDefinition } from 'model/auth'

export const enum UserType {
  nobody = 'nobody',

  /**
   * 共享中心管理员
   */
  fscAdmin = 'fscAdmin',

  /**
   * 代账公司管理员
   */
  acAdmin = 'acAdmin',

  /**
   * 个人会计师
   */
  individual = 'individual',
  /**
   * 解放君管理员
   */
  jfjAdmin = 'jfjAdmin',

  /**
   * 个人版
   */
  personal = 'personal'
}

/**
 * 用户类型定义
 *
 * @export
 * @class User
 */
export class User {
  /**
   * id
   *
   * @type {string}
   * @memberof User
   */
  _id: string = ''

  /**
   * 用户所属中心id
   *
   * @type {string}
   * @memberof User
   */
  group: string = ''

  /**
   * 姓名
   *
   * @type {string}
   * @memberof User
   */
  name: string = ''

  /**
   * 密码
   *
   * @type {string}
   * @memberof User
   */
  password: string = ''

  /**
   * 邮件
   *
   * @type {string}
   * @memberof User
   */
  email: string = ''

  /**
   * 手机号
   *
   * @type {string}
   * @memberof User
   */
  phone: string = ''

  /**
   * 职位
   *
   * @type {string}
   * @memberof User
   */
  position: string = ''

  /**
   * 上级用户
   *
   * @type {string}
   * @memberof User
   */
  parent?: string = ''

  /**
   * 下级用户
   *
   * @type {(string[])}
   * @memberof User
   */
  children: string[] = []
  /**
   * 个人极速版用户注册时间
   *
   * @type {(string[])}
   * @memberof User
   */
  createdAt = ''
  /**
   * 偏好设置
   *
   * @type {{ [key: string]: any }}
   * @memberof User
   */
  preference: { [key: string]: any }

  /**
   * 用户级别的操作黑名单
   *
   * @type {ActionDefinition[]}
   * @memberof User
   */
  deny: ActionDefinition[] = []

  /**
   * 模块权限组
   *
   * @type {any[]}
   * @memberof User
   */
  actions: any[] = []

  /**
   * 是否启用
   *
   * @type {boolean}
   * @memberof User
   */
  disabled: boolean = false

  /**
   * 是否可以辅助上级
   *
   * @type {boolean}
   * @memberof User
   */
  // assistParent: boolean = false

  /**
   * 可见的客户范围（仅限共享中心）
   *
   * @type {{_id: string, name:string}[]}
   * @memberof User
   */
  acRole: { _id: string; name: string; nickName: string }[] = []

  /**
   * 用户类型
   *
   * @type {UserType}
   * @memberof User
   */
  type: UserType = UserType.nobody

  /**
   * 是否为代理记账体系用户
   *
   * @type {string}
   * @memberof Account
   */
  isCustomer?: boolean = false

  /**
   * 用户所属的组范围，用于标记共享中心或管理帐体系
   *
   * 可能的值为“基础财税”，及其他管理帐项目企业名
   * 注意此字段为临时性方案。
   *
   * @type {string}
   * @memberof User
   */
  client: string = ''

  routepkg: any = {}
  /**用户来源 */
  source: any = ''
  /**
   * 个人用户的微信信息
   *
   * @memberof User
   */
  wxInfo?: any = {
    headimgurl: '',
    nickname: '',
    openid: ''
  }

  /**EPSON代理商层级 */
  agentLevel = 0
  /**EPSON代理商绑定的企业信息 */
  agentInfo = {
    pub: ''
  }
  /**
   * @description 是否完成新手引导
   * @type {('begin' | 'end')}
   * @memberof User
   */
  guide?: 'begin' | 'end'

  constructor(raw: any = {}) {
    assign(this, raw)
  }

  /**
   * 是否为解放君管理员
   *
   * @readonly
   * @type {boolean}
   * @memberof User
   */
  public get isJfjRoot(): boolean {
    return this.type === 'jfjAdmin'
  }
  /**
   * 是否为内部中心管理员
   *
   * @readonly
   * @type {boolean}
   * @memberof User
   */
  public get isFscAdmin(): boolean {
    return this.type === 'fscAdmin'
  }

  /**
   * 是否为内部中心会计专家
   *
   * @readonly
   * @type {boolean}
   * @memberof User
   */
  public get isFscAccountant(): boolean {
    return this.type === UserType.nobody && !!!this.isCustomer
  }

  /**
   * 是否为内部中心人员
   *
   * @readonly
   * @type {boolean}
   * @memberof User
   */
  public get isFscRelated(): boolean {
    return this.isFscAdmin || this.isFscAccountant
  }

  /**
   * 是否为代账公司的管理员
   *
   * @readonly
   * @type {boolean}
   * @memberof User
   */
  public get isAcAdmin(): boolean {
    return this.type === 'acAdmin'
  }

  /**
   * 是否为代账公司的会计顾问
   *
   * @readonly
   * @type {boolean}
   * @memberof User
   */
  public get isAcAccountant(): boolean {
    return this.type === UserType.nobody && !!this.isCustomer
  }

  /**
   * 是否为代账公司人员
   *
   * @readonly
   * @type {boolean}
   * @memberof User
   */
  public get isAcRelated(): boolean {
    return !!this.isCustomer
  }

  /**
   * 是否为管理帐用户
   *
   * @readonly
   * @type {boolean}
   * @memberof User
   */
  public get isMgrRelated(): boolean {
    return this.client !== '基础财税'
  }

  /**
   * 是否为钉钉用户
   *
   * @readonly
   * @type {boolean}
   * @memberof User
   */
  public get isDDUser(): boolean {
    return this.source == '钉钉'
  }
}


export interface WxInfo {
  openid:string,
  nickname:string,
  sex:number,
  province:string,
  city:string,
  country:string,
  headimgurl: string,
  unionid: string
}
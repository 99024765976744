import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Workflow, WorkflowParam } from '../workflow.util';
import './spin.scss';

@Component
export default class Spin extends Vue {
  private visible: boolean = false;
  private expand: boolean = false;
  private transitionToken: number = -1;
  private msg: string = 'Working on it...';

  constructor() {
    super();

    Workflow.define<string>('spinner.show', this.show);
    Workflow.define('spinner.hide', this.hide);
  }

  private show({ data }: WorkflowParam<string>) {
    this.msg = data;

    if (this.transitionToken !== -1) {
      clearTimeout(this.transitionToken);
      this.transitionToken = -1;
    }

    this.visible = true;
    requestAnimationFrame(() => {
      this.expand = true;
    });
  }

  private hide() {
    this.expand = false;
    this.transitionToken = window.setTimeout(() => {
      this.visible = false;
      this.transitionToken = -1;
    }, 300);
  }

  private get calSpinStyle() {
    return {
      display: this.visible ? 'flex' : 'none',
      'pointer-events': this.expand ? 'unset' : 'none',
      opacity: this.expand ? '1' : '0',
    };
  }

  public render(h) {
    return (
      <div class="jfj-spin" style={this.calSpinStyle}>
        <div class="spinner">
          {this.expand && [
            <div class="ball ball1">
              <div class="center" />
            </div>,
            <div class="ball ball2">
              <div class="center" />
            </div>,
            <div class="ball ball3">
              <div class="center" />
            </div>,
          ]}
        </div>
        <p class="msg">{this.msg}</p>
      </div>
    );
  }
}

import { ActionDefinition } from 'model/auth'
import ApiService from './authService.api'

class AuthService {
  private setUser: Set<string> = new Set()
  private setAccount: Set<string> = new Set()

  public fillUserBlacklist(blacklist: string[]) {
    this.setUser = new Set(blacklist)
  }

  public fillAccountBlacklist(blacklist: string[]) {
    this.setAccount = new Set(blacklist)
  }

  public async fillAccountBlacklistAsync(accountId: string, userId: string, acId?: string) {
    this.setAccount = new Set(await ApiService.fetchBlacklist({
      accountId,
      userId,
      acId
    }))
  }

  public clearUserBlacklist() {
    this.setUser.clear()
  }

  public clearAccountBlacklist() {
    this.setAccount.clear()
  }

  public can(action: ActionDefinition | string): boolean {
    // 特例-增长统计
    if (action === ActionDefinition.增长统计) {
      return this.isAllow(ActionDefinition.增长统计_账套) ||
        this.isAllow(ActionDefinition.增长统计_客户) ||
        this.isAllow(ActionDefinition.增长统计_人员)
    }

    if (action === ActionDefinition.个人中心) {
      return true
    }
    if (action === ActionDefinition.资产模块) {
      return this.isAllow(ActionDefinition.固定资产) ||
        this.isAllow(ActionDefinition.无形资产) 
    }

    if (action === ActionDefinition.辅助模块) {
      return this.isAllow(ActionDefinition.固定资产) ||
        this.isAllow(ActionDefinition.无形资产) ||
        this.isAllow(ActionDefinition.库存模块) ||
        this.isAllow(ActionDefinition.工资模块) ||
        this.isAllow(ActionDefinition.摊销模块) ||
        this.isAllow(ActionDefinition.辅助核算) ||
        this.isAllow(ActionDefinition.往来调账) 
    }
    return this.isAllow(action)
  }

  private isAllow(action: ActionDefinition | string): boolean {
    return !this.setUser.has(action) && !this.setAccount.has(action)
  }
}

export default new AuthService()

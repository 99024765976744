import { ApiService as service, ApiServiceBase, ApiCallingFn } from './apiService'

interface ApiService extends ApiServiceBase {
  fetchMetalist: ApiCallingFn
  fetchMetaDetail: ApiCallingFn
  createMeta: ApiCallingFn
  updateMeta: ApiCallingFn
  deleteMeta: ApiCallingFn
  fetchFormulas: ApiCallingFn
  updateFormulaInfo: ApiCallingFn
  updateFormula: ApiCallingFn
  deleteFormula: ApiCallingFn
  fetchMetaHint: ApiCallingFn
  fetchTransactions: ApiCallingFn
  fetchCategories: ApiCallingFn
  updateCategory: ApiCallingFn
  deleteCategory: ApiCallingFn
  fetchCommonFormulas: ApiCallingFn
  fetchCommonCategories: ApiCallingFn
  transferFormulas: ApiCallingFn
  fetchSourceOptions: ApiCallingFn
  probabilityCompensate: ApiCallingFn
  batchEditMetadata: ApiCallingFn
  editInvoiceFile: ApiCallingFn
  scannerDelete: ApiCallingFn

  templateDelete: ApiCallingFn

  requeryDetail: ApiCallingFn
}

const ApiService = service as ApiService
ApiService.define([
  { type: 'json', name: 'fetchMetalist', path: 'metadata/list' },
  { type: 'json', name: 'fetchMetaDetail', path: 'metadata/detail' },
  { type: 'json', name: 'createMeta', path: 'metadata/add' },
  { type: 'json', name: 'updateMeta', path: 'metadata/edit' },
  { type: 'json', name: 'deleteMeta', path: 'metadata/delete' },
  { type: 'json', name: 'fetchFormulas', path: 'metadata/template/list' },
  { type: 'json', name: 'updateFormulaInfo', path: 'metadata/template/createOrUpdate' },
  { type: 'json', name: 'updateFormula', path: 'metadata/template/config' },
  { type: 'json', name: 'deleteFormula', path: 'metadata/template/remove' },
  { type: 'json', name: 'fetchMetaHint', path: 'metadata/columns' },
  { type: 'json', name: 'fetchTransactions', path: 'metadata/transaction' },
  { type: 'json', name: 'fetchCategories', path: 'metadata/category/list' },
  { type: 'json', name: 'updateCategory', path: 'metadata/category/createOrUpdate' },
  { type: 'json', name: 'deleteCategory', path: 'metadata/category/remove' },
  { type: 'json', name: 'fetchCommonFormulas', path: 'metadata/template/common' },
  { type: 'json', name: 'fetchCommonCategories', path: 'metadata/category/sourceOptions' },
  { type: 'json', name: 'transferFormulas', path: 'metadata/template/clone' },
  { type: 'json', name: 'fetchSourceOptions', path: 'metadata/category/sourceOptions' },
  { type: 'json', name: 'probabilityCompensate', path: 'metadata/updateProb' },
  { type: 'json', name: 'batchEditMetadata', path: 'metadata/batchEdit' },
  { type: 'json', name: 'editInvoiceFile', path: 'file/invoice/edit' },
  { type: 'json', name: 'scannerDelete', path: 'metadata/scanner/delete' },
  // 批量删除模板集
  { type: 'json', name: 'templateDelete', path: 'metadata/template/removeCommon' },
  // 重新查询商品明细
  { type: 'json', name: 'requeryDetail', path: 'metadata/requeryDetail' },
])

export default ApiService

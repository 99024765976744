import { ApiService as service, ApiServiceBase, ApiCallingFn } from './apiService'
import { Client } from 'model/client'

interface ApiService extends ApiServiceBase {
  fetchClients: ApiCallingFn<any, Client[]>
  createClient: ApiCallingFn<any, Client>
  updateClient: ApiCallingFn<any, Client>
  deleteClient: ApiCallingFn<any, void>
}

const ApiService = service as ApiService
ApiService.define([
  { type: 'json', name: 'fetchClients', path: 'customer/list' },
  { type: 'json', name: 'createClient', path: 'customer/create' },
  { type: 'json', name: 'updateClient', path: 'customer/edit' },
  { type: 'json', name: 'deleteClient', path: 'customer/delete' },
])

export default ApiService

import { idGen, deepclone } from "util/common";
import { AssistItem } from 'page/assist/model/assist'
import { Subject } from 'model/subject';
/**
 * 分录
 * 
 * @export
 * @class Entry
 */
export class Entry {
  /**
   * 用于视图层的key
   * 
   * @type {string}
   * @memberof Entry
   */
  uid: string = idGen()

  /**
   * 科目id
   * 
   * @type {string}
   * @memberof Voucher
   */
  _id: string = ''

  /**
   * 摘要
   * 
   * @type {string}
   * @memberof Entry
   */
  brief: string = ''

  /**
   * 贷方金额
   * 
   * @type {number}
   * @memberof Entry
   */
  credit: number = 0

  /**
   * 借方金额
   * 
   * @type {number}
   * @memberof Entry
   */
  debit: number = 0

  /**
   * 科目名称
   * 
   * @type {string}
   * @memberof Entry
   */
  name: string = ''

  /**
   * 科目编号
   * 
   * @type {string}
   * @memberof Entry
   */
  subject: string = ''
  /**
   * @description 
   * @type {boolean}
   * @memberof Entry
   */
  /**
   * @description 是否停用
   * @type {boolean}
   * @memberof Entry
   */
  suspend: boolean = false

  /**
   * 辅助核算项
   *
   * @type {AssistItem[]}
   * @memberof Entry
   */
  items: AssistItem[] = []

  /**
   * 数量
   * 
   * @type {number}
   * @memberof Voucher
   */
  quantities: number = 1

  /**
   * 单价
   * 
   * @type {number}
   * @memberof Entry
   */
  price: number = 0

  /**
   * 单位
   * 
   * @type {string}
   * @memberof Entry
   */
  unit: string = ''

  /**
   * 原币
   * 
   * @type {number}
   * @memberof Entry
   */
  currencies: number = 0

  /**
   * 汇率
   * 
   * @type {number}
   * @memberof Entry
   */
  parities: number = 1

  /**
   * 币种
   * 
   * @type {string}
   * @memberof Entry
   */
  currency: string = 'CNY'

  /**
   * 票据
   * 
   * @type {Resource[]}
   * @memberof Voucher
   */
  // resources: Resource[] = []

  constructor (raw: { [key: string]: any } = {}) {
    Object.assign(this, deepclone(raw))
  }

  /**
   * 判断当前分录是否为空分录
   * 
   * @readonly
   * @type {boolean}
   * @memberof Entry
   */
  get isEmpty(): boolean {
    return this.brief === '' &&
      this._id === '' &&
      this.debit === 0 &&
      this.credit === 0
  }

  /**
   * 判断当前分录是否包含数量核算
   * 
   * @readonly
   * @type {boolean}
   * @memberof Entry
   */
  get hasAmount(): boolean {
    return this.unit !== ''
    // return true
  }

  /**
   * 判断当前分录是否包含外币核算
   * 
   * @readonly
   * @type {boolean}
   * @memberof Entry
   */
  get hasForeign(): boolean {
    return this.currency !== 'CNY'
  }

  /**
   * 获取拼接后辅助核算项科目
   * 
   * @readonly
   * @type {string}
   * @memberof Entry
   */
  get fullItemSubject(): string {
    return this.items.reduce((str, item) => {
      return `${str}_${item.subject}`
    }, '')
  }

  /**
   * 获取拼接后辅助核算项名称
   * 
   * @readonly
   * @type {string}
   * @memberof Entry
   */
  get fullItemName(): string {
    return this.items.reduce((str, item) => {
      return `${str}_${item.name}`
    }, '')
  }

  /**
   * 判断当前分录是否包含辅助核算
   * 
   * @readonly
   * @type {boolean}
   * @memberof Entry
   */
  hasAssist(subjects: Subject[]): boolean {
    let subject = subjects.find(sub => sub.subject === this.subject)

    return subject && !!subject.items.length
  }
}
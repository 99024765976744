import { Entry } from './entry'
import { Resource } from './resource'
import * as Accounting from 'util/accounting'
import { deepclone } from 'util/common'

/**
 * 凭证生成来源
 *
 * @export
 * @enum {number}
 */
export enum VoucherSource {
  系统生成 = 'auto',
  库存系统生成 = 'autoStock',
  手动新建 = '手动新建',
  业务数据 = '业务数据',
  业务来源 = '业务来源',
  调账模块 = '调账模块',
  资产处理 = '资产处理（非折旧）',
  库存 = '库存'
}

/**
 * 凭证审核状态
 *
 * @export
 * @enum {number}
 */
export const enum VoucherAuditState {
  待审核 = '待审核',
  已审核 = '已审核'
}

/**
 * 基础财税凭证汇总规则
 *
 * @export
 * @enum {number}
 */
export const FscSummarizingRules: any[] = [
  {
    name: '银行数据',
    columns: [{ name: '序号' }, { name: '交易时间' }, { name: '收付款人' }, { name: '币种' }, { name: '业务' }]
  },
  {
    name: '进项数据',
    columns: [{ name: '销货单位' }, { name: '发票号码' }, { name: '开票日期' }, { name: '业务' }]
  },
  {
    name: '销项数据',
    columns: [{ name: '购货单位' }, { name: '发票类型' }, { name: '发票号码' }, { name: '开票日期' }, { name: '业务' }]
  },
  {
    name: '费用数据',
    columns: [{ name: '报销人' }, { name: '报销单号' }, { name: '报销部门' }, { name: '开票日期' }, { name: '序号' }, { name: '业务' }]
  },
]

/**
 * 凭证
 *
 * @export
 * @class Voucher
 */
export class Voucher {
  /**
   * id
   *
   * @type {string}
   * @memberof Voucher
   */
  _id: string = ''

  /**
   * 分录
   *
   * @type {Entry[]}
   * @memberof Voucher
   */
  entries: Entry[] = []

  /**
   * 所属账期
   *
   * @type {string}
   * @memberof Voucher
   */
  issue: string = ''

  /**
   * 编号
   *
   * @type {number}
   * @memberof Voucher
   */
  number: number = 0

  /**
   * 票据数量
   *
   * @type {number}
   * @memberof Voucher
   */
  resourcesCount?: number = 0

  /**
   * 单据数量
   *
   * @type {number}
   * @memberof Voucher
   */
  attachesCount?: number = 0

  /**
   * 制单日期（YYYY-MM-DD）
   *
   * @type {string}
   * @memberof Voucher
   */
  createTime: string = ''

  /**
   * 票据
   *
   * @type {Resource[]}
   * @memberof Voucher
   */
  resources?: Resource[] = []

  /**
   * 生成来源
   *
   * @type {VoucherSource}
   * @memberof Voucher
   */
  source: VoucherSource = VoucherSource.手动新建

  /**
   * 审核状态
   *
   * @type {VoucherAuditState}
   * @memberof Voucher
   */
  state: VoucherAuditState = VoucherAuditState.待审核

  /**
   * 批注
   *
   * @type {string}
   * @memberof Voucher
   */
  remark: string = ''

  /**
   * 凭证类型
   *
   * @type {string}
   * @memberof Voucher
   */
  type: string = ''

  /**
   * 凭证时间
   *
   * @type {string}
   * @memberof Voucher
   */
  resourceTime: string = ''

  /**
   * 是否展开凭证内容
   *
   * @type {boolean}
   * @memberof Voucher
   */
  isExpand: boolean = true

  /**
   * 凭证编号锁定flag
   *
   * @type {boolean}
   * @memberof Voucher
   */
  public lockNumber: boolean = false

  constructor (raw: { [key: string]: any } = {}) {
    Object.assign(this, deepclone(raw))

    this.entries = (raw.entries || []).map(entry => new Entry(entry))
    this.resources = (raw.resources || []).map(resource => new Resource(resource, this._id, this.type))
  }

  /**
   * 创建包含指定行数空分录的空白凭证
   *
   * @static
   * @param {number} entryCounts 分录行数
   * @returns {Voucher} 空白凭证
   * @memberof Voucher
   */
  static createEmpty(entryCounts: number): Voucher {
    return new Voucher({
      entries: Array.from(Array(entryCounts))
    })
  }

  /**
   * 获取凭证编号
   *
   * @returns {string}
   * @memberof Voucher
   */
  get index(): string {
    return `记-${this.number.toString().padStart(3, '0')}`
  }

  /**
   * 获取凭证来源名称
   *
   * @readonly
   * @type {string}
   * @memberof Voucher
   */
  get sourceName(): string {
    switch (this.source) {
      case VoucherSource.手动新建:
        return '手动新建'
      case VoucherSource.系统生成:
      case VoucherSource.库存系统生成:
      case VoucherSource.业务来源:
      case VoucherSource.库存:
      case VoucherSource.调账模块:
      case VoucherSource.资产处理:
        return '系统生成'
      case VoucherSource.业务数据:
        return '业务数据'
      default:
        return '手动新建'
    }
  }

  /**
   * 借方合计数
   *
   * @readonly
   * @type {number}
   * @memberof Voucher
   */
  get sumDebit(): number {
    return Accounting.getSum(this.entries, 'debit')
  }

  /**
   * 贷方合计数
   *
   * @readonly
   * @type {number}
   * @memberof Voucher
   */
  get sumCredit(): number {
    return Accounting.getSum(this.entries, 'credit')
  }

  /**
   * 当前凭证是否为系统凭证
   *
   * @readonly
   * @type {boolean}
   * @memberof Voucher
   */
  get isSystem(): boolean {
    return this.source === VoucherSource.系统生成
  }

  /**
   * 当前凭证是否为空
   *
   * @readonly
   * @type {boolean}
   * @memberof Voucher
   */
  get isEmpty(): boolean {
    return !this.entries.some(entry => !entry.isEmpty)
  }

  /**
   * @description 当前是否存在科目为空的数据
   * @readonly
   * @type {boolean}
   * @memberof Voucher
   */
  get isEntrySomeEmpty(): boolean {
    return this.entries.some(entry => {
      const dit = entry.credit || entry.debit
      return dit && !entry.name
    })
  }

  /**
   * 当前凭证分录借贷是否平衡
   *
   * @readonly
   * @type {boolean}
   * @memberof Voucher
   */
  get isBalanced(): boolean {
    return Accounting.getDiff(this.entries) === 0
  }

  /**
   * 当前凭证是否已审核
   *
   * @readonly
   * @type {boolean}
   * @memberof Voucher
   */
  get isAudited(): boolean {
    return this.state === VoucherAuditState.已审核
  }

  /**
   * 当前凭证的检索关键字
   *
   * @readonly
   * @type {string}
   * @memberof Voucher
   */
  get searchKey(): string {
    return this.entries.reduce(
      (key, entry) => key + ' ' +
        entry.brief + ' ' +
        entry.subject + ' ' +
        entry.name + ' ' +
        entry.debit + ' ' +
        entry.credit + ' ' +
        this.index + ' ' +
        this.type + ' ' +
        this.sourceName + ' ' +
        entry.items.reduce((s, item) => s + item.name + ' ', ''),
      '',
    )
  }

  /**
   * 当前凭证的首行摘要
   *
   * @readonly
   * @type {string}
   * @memberof Voucher
   */
  get brief(): string {
    return this.entries.length !== 0 ? this.entries[0].brief : ''
  }

  /**
   * 删除当前凭证中所有空分录
   *
   * @memberof Voucher
   */
  trimEntries() {
    this.entries = this.entries.filter(entry => !entry.isEmpty)
  }
}

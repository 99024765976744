import Vue from "vue";
import Col from './col'
import { Component, Prop, Watch } from 'vue-property-decorator'
import './row.scss'

@Component
export default class Row extends Vue {
  @Prop({ default: 0 })
  gutter: number

  get cols(): Col[] {
    return this.$children.filter(c => c.$options.name === 'Col') as Col[]
  }

  get style(): { [key: string]: string } {
    return {
      'margin-left': `${-this.gutter / 2}px`,
      'margin-right': `${-this.gutter / 2}px`
    }
  }

  mounted() {
    this.cols.forEach(col => { col.gutter = this.gutter })
  }

  render(h) {
    return <div class="jfj-grid-row" style={this.style}>{this.$slots.default}</div>
  }
}
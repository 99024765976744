import { ApiService as service, ApiServiceBase, ApiCallingFn } from 'service'

interface ApiService extends ApiServiceBase {

  guideBegin: ApiCallingFn
  guideEnd: ApiCallingFn
}

const ApiService = service as ApiService
ApiService.define([
  { type: 'json', name: 'guideBegin', path: 'personal/guide/begin' },
  { type: 'json', name: 'guideEnd', path: 'personal/guide/end' },

])

export default ApiService
